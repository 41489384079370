import { Box, ListItem, ListItemIcon } from '@mui/material'
import React from 'react'
import { NavIconButton } from './NavIconButton'
import { Link } from 'react-router-dom'

const NavItem = ({ item, isActive, open,drawerToggle }) => {
    return (
        <ListItem sx={{ display: 'flex', justifyContent: "flex-start", width: "100%" }}>
            <Link to={item?.path} onClick={drawerToggle} style={{textDecoration: "none", width: "100%"}}>
                <NavIconButton item={item} isActive={isActive} open={open} />
            </Link>
        </ListItem>
    )
}

export default NavItem