import React, { useState } from 'react';
import { Box, TextField, styled } from '@mui/material';
import { useTheme } from '@emotion/react';

const CustomDatePickerTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: theme.palette.customColors.whiteBG,
    border: `1px solid ${theme.palette.customColors.g75}`,
    borderRadius: '4px',
    padding: '0px 0px 0px',
    '&:hover': {
      backgroundColor: theme.palette.customColors.whiteBG,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.customColors.whiteBG,
      borderColor: theme.palette.customColors.g75,
    },
    '& .MuiFilledInput-input': {
      padding: '13px 15px',
      height: 'auto',
      boxSizing: 'border-box',
      '&::-webkit-datetime-edit': {
        // color: 'transparent',
      },
      '&::-webkit-datetime-edit-fields-wrapper': {
        // color: 'red',
        display:'none'
      },
      '&::-webkit-datetime-edit-month-field': {
        // color: 'transparent',
        display:'none'

      },
      '&::-webkit-datetime-edit-day-field': {
        // color: 'transparent',
        display:'none'

      },
      '&::-webkit-datetime-edit-year-field': {
        // color: 'transparent',
        display:'none'

      },
      '&::-webkit-inner-spin-button': {
        // display: 'none',
      },
      '&::-webkit-calendar-picker-indicator': {
        opacity: 0,
      },
    },
  },
  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.customColors.g100,
    opacity: 1,
  },
}));

const CustomDatePicker = ({ label, placeholder, ...props }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box component="label" sx={{ color: theme.palette.customColors.g500, marginBottom: '8px', display: 'block' }}>
        {label}
      </Box>
      <Box sx={{ position: 'relative', width: 'fit-content' }}>
        <CustomDatePickerTextField
          variant="filled"
          type="date"
          placeholder={placeholder}
          fullWidth
          InputProps={{ disableUnderline: true }}
          // onChange={handleChange}
          {...props}
        />
        
          <Box
            component="label"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '15px',
              transform: 'translateY(-50%)',
              color: theme.palette.customColors.g100,
              pointerEvents: 'none',
              zIndex: 0,
              fontSize: '16px',
            }}        
          >
            {/* {placeholder} */}
          </Box>
      
      </Box>
    </Box>
  );
};

export default CustomDatePicker;
