import { useTheme } from '@emotion/react'
import { IconButton } from '@mui/material'
import React from 'react'
import { SECONDARY } from 'ui-components/CustomButton'
import { ReactComponent as InfoIcon } from 'assets/help-circle.svg';

const NeedHelpButton = () => {
    const theme = useTheme()
    return (
        <>
            <a href="mailto:support@backrr.com">
                <SECONDARY
                    sx={{
                        background: theme?.palette?.customColors.indigo50,
                        color: theme?.palette?.customColors?.indigo700,
                        fontWeight: 600,
                        fontSize: "14px",
                        padding: "10px 16px",
                        display: { xs: "none", md: 'inline-block' }

                    }}
                    onClick={() => { }}
                >

                    Need help?
                </SECONDARY>
                <IconButton sx={{ display: { xs: "inline-block", md: 'none' }, padding: '5px' }}>
                    <InfoIcon style={{ width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', gap: "4px", marginBlock: 'auto', stroke: '#444CE7' }} />
                </IconButton>
            </a>
        </>
    )
}

export default NeedHelpButton