import { Box, Card, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from "../../assets/rounds_icons/edit_icon.svg";
import PieChart from '../../ui-components/PieChart'
import { useTheme } from '@emotion/react';
import { colorArray, consumerBrandColor, convertCurrencyAmountRange, handleCopyClick, handleDateValues, isTitleToShow, marketplaceColor, sectorAgnostic, servicesColor, technologyColor } from '../../utils';
import { WHITEBG } from '../../ui-components/CustomButton';
import moment from 'moment';
import marketplaceIcon from '../../assets/marketplace.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'
import { ContentCopyOutlined, FileCopy, LinkedIn } from '@mui/icons-material';
import indiaFlag from '../../assets/india_flag.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CheckIcon from '@mui/icons-material/Check';

const CardHeader = ({ title, action = () => { } }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
        <WHITEBG
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
                minWidth: "0 !important",
                border: `1px solid #D0D5DD !important`,
                display: "flex",
                gap: '0.5rem',
                paddingInline: '0.5rem 0.875rem'
            }}
            onClick={action}
        >
            <EditIcon
                style={{
                    width: 20,
                    height: 20,
                }} />
            <Typography>
                Edit
            </Typography>
        </WHITEBG>
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "" }) => {
    return (
        <>
            {htmlContent && htmlContent?.length > 0 ?
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} style={{ color: color }} />
                : "-"
            }
        </>
    );
};

const PersonalDetails = ({ data: personalDetails, avatarList: avatarList }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    console.log("personalDetails", personalDetails)
    let profileImage = null
    profileImage = personalDetails?.profile_path

    const getCategoryIcon = (category) => {
        switch (category?.name) {
            case "Technology": {
                return laptopScreenIcon
                // return <img src={laptopScreenIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto' }} alt='icon' />
            }
            case "Consumer Brand": {
                return mobileShoppingIcon
                // return <img src={mobileShoppingIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto' }} alt='icon' />

            }
            case "Marketplace": {
                return marketplaceIcon
                // return <img src={marketplaceIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto', paddingRight: "0.6rem" }} alt='icon' />
            }

            case "Services": {
                return digitalServiceIcon
                // return <img src={digitalServiceIcon} style={{ height: "4.15rem", width: "4.15rem", paddingTop: "1rem", marginLeft: 'auto', transform: 'rotateY(180deg)' }} alt='icon' />
            }
        }
    }

    const getStageIcon = (stage) => {
        switch (stage.name) {
            case "Pre-Seed": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", height: "1.8rem", paddingTop: "4rem", marginLeft: 'auto' }} />
            }
            case "Seed": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", height: "2.649rem", paddingTop: "3.5rem", marginLeft: 'auto' }} />
            }
            case "Early Stage": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", height: "3.649rem", paddingTop: "2.5rem", marginLeft: 'auto' }} />
            }
            case "Growth": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", height: "5.2rem", paddingTop: "1rem", marginLeft: 'auto' }} />
            }
            case "Expansion": {
                return <img src={stage.image_path} rel='preload' style={{ width: "5rem", paddingTop: "1rem", marginLeft: 'auto' }} />
            }
        }
    }

    const startup_sub_categories = [{
        "name": "Alcohol & Beverages",
        "description": "Direct-to-consumer alcohol brands, including craft beer, wine, and spirits.",
        "_id": "66f553d50450954f2c2f959f"
    },
    {
        "name": "Art & Home Décor",
        "description": "Customizable artwork, furniture, and décor for homes and offices.",
        "_id": "66f553d50450954f2c2f95a0"
    },
    {
        "name": "Athleisure",
        "description": "Apparel that combines athletic performance with casual, everyday wear.",
        "_id": "66f553d50450954f2c2f95a1"
    }]


    const commonCardStyles = {
        padding: '1rem',
        // paddingBottom: '1rem',
        boxShadow: 'none',
        // background: 'transparent',
        backgroundColor: "#FFFAF5",
        overflow: 'hidden',
        border: `1px solid ${theme.palette.customColors.gray300}`,
        borderRadius: '15px',
    };

    const getCategoryColor = (category) => {
        switch (category) {
            case "Technology": { return technologyColor }
            case "Consumer Brand": { return consumerBrandColor }
            case "Marketplace": { return marketplaceColor }
            case "Services": { return servicesColor }
            default: { return sectorAgnostic }
        }
    }

    const getRandomColor = () => {
        var copy = colorArray.slice(0);
        return function () {
            if (copy.length < 1) { copy = colorArray.slice(0); }
            var index = Math.floor(Math.random() * copy.length);
            var item = copy[index];
            copy.splice(index, 1);
            return item;
        };
    }

    const [copied, setCopied] = React.useState(false)

    return (
        <Grid item container xs={12} gap={"1.25rem"} display={"grid"}
            //  gridTemplateAreas={`"first" "aside" "third" "aside2" "third" "forth" "fifth" "sixth"`}
            // gridTemplateAreas={`"first" "aside" "third"`}
            sx={{
                maxWidth: '900px !important',
                marginInline: 'auto',
                paddingBottom: '2rem'
                // height: '100%',
                // paddingTop: '1.5rem !important'
            }}
            gridTemplateAreas={`"first" "second" "third" "fourth" "fifth" "sixth" "seventh"`}
            gridTemplateColumns={"1fr"} justifyContent={"space-between"}>
            <Grid sx={{ gridArea: 'first', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item >
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Personal Info"} action={() => { navigate(`/profile/edit_profile?page=0`) }} />
                    <Grid container xs={12} alignItems={"center"} spacing={2} marginInline={"auto"}>
                        <Grid item xs={12} pl={"0 !important"}>
                            <img src={profileImage} style={{ borderRadius: '8px', maxWidth: '10rem', marginInline: 'auto', display: "block" }} />
                        </Grid>
                        <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                            <Typography variant='title' sx={{ fontSize: "1.3rem", textAlign: 'center', marginInline: 'auto' }}>{personalDetails?.title ? isTitleToShow(personalDetails?.title) ? personalDetails?.title : "" : ""} {personalDetails?.first_name + " " + personalDetails?.last_name}</Typography>
                        </Grid>
                        {personalDetails?.date_of_birth != null && <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} padding={'0rem 1rem 0.5rem 1rem'}>
                            <Typography fontSize={"0.9rem"} variant='bodyTextRegular' sx={{ textAlign: 'center', marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '0.1rem' }}>{String(personalDetails?.date_of_birth)}</Typography>
                        </Grid>}
                        {personalDetails?.bio != null && <Grid container xs={12} alignItems={"center"} marginInline={"auto"} display={"flex"} padding={'0rem 1rem 0.5rem 1rem'}>
                            <Typography fontSize={"0.9rem"} variant='bodyTextRegular' sx={{ textAlign: 'center', marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '0.1rem' }}>{personalDetails?.bio}</Typography>
                        </Grid>}
                        <Typography variant='bodyTextRegular' sx={{ fontSize: { xs: "0.8rem", md: "0.8rem" }, textAlign: 'center', marginInline: 'auto' }}>{personalDetails?.city_name}{personalDetails?.city_name && personalDetails?.state_name && ", "}{personalDetails?.state_name}{((personalDetails?.state_name && personalDetails?.country_name) || (personalDetails?.city_name && personalDetails?.country_name)) && ", "}{personalDetails?.country_name}
                            {personalDetails?.country_name == "India" && <span>
                                <img src={indiaFlag} rel='preload' style={{ width: "1.2rem", marginLeft: '5px' }} />
                            </span>}
                        </Typography>

                        <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                            <Tooltip title="Linkedin"><button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LinkedInIcon sx={{ fontSize: 30, borderRadius: 10 }} style={{ display: 'inline-block' }} onClick={() => { window.open(personalDetails?.profile_url) }} /></button></Tooltip>
                            {/* <Tooltip title={copied ? "Copied to Clipboard" : "Copy Profile URL"}><button type='button' style={{ backgroundColor: 'transparent', padding: 0 }} onClick={() => handleCopyClick("Copied", copied, setCopied)} >
                                {
                                    copied ? <FileCopy sx={{ fontSize: 25 }} style={{ display: 'inline-block' }} />
                                        : <ContentCopyOutlined sx={{ fontSize: 25 }} />
                                }
                            </button></Tooltip> */}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'second', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Categories"} action={() => { navigate(`/profile/edit_profile?page=1`) }} />
                    <Grid
                        container
                        xs={12}
                        gap={1}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        {
                            personalDetails?.category_info?.map((item, index) => {
                                return (
                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor(item.name) }}>
                                        <img src={getCategoryIcon(item)} style={{ width: "0.9rem", height: "0.9rem", objectFit: 'contain' }} />
                                        <Grid>
                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, marginLeft: "0.3rem" }}>{item.name}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'third', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Sectors"} action={() => { navigate(`/profile/edit_profile?page=1`) }} />
                    <Grid
                        container
                        xs={12}
                        gap={1}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                        {personalDetails?.sector_type !== "All" ?
                            personalDetails?.sector_info?.map((item, index) => {
                                return (
                                    <>
                                        {
                                            item.config_values?.map(sector => {
                                                return (
                                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor(item.config_type) }}>
                                                        <Grid>
                                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>{sector.sub_category}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }) :
                            <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor("") }}>
                                <Grid>
                                    <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>Sector Agnostic</Typography>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'fourth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Business Models"} action={() => { navigate(`/profile/edit_profile?page=1`) }} />
                    <Grid
                        container
                        xs={12}
                        gap={1}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                        {personalDetails?.business_model_type !== "All" ?
                            personalDetails?.business_model_info?.map((item, index) => {
                                return (
                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getRandomColor() }}>

                                        <Grid>
                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>{item.name}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }) :
                            <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', padding: "12px", justifyContent: 'center', alignItems: 'center', backgroundColor: getCategoryColor("") }}>
                                <Grid>
                                    <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>Select All</Typography>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'fifth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Stages"} action={() => { navigate(`/profile/edit_profile?page=1`) }} />
                    <Grid
                        container
                        display={'grid'}
                        gridTemplateColumns={'repeat(auto-fill, minmax(16rem, 1fr))'}
                        xs={12}
                        gap={2}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        {
                            personalDetails?.stage_info?.map(item => {
                                return (
                                    <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '8px', justifyContent: 'start', alignItems: 'normal' }}>
                                        <Grid display={'flex'} sx={{ padding: "12px 10px", backgroundColor: item.disable ? theme.palette.customColors.disable : theme.palette.customColors.indigo600, borderRight: `1px solid ${theme.palette.customColors.g75}`, borderRadius: '7px', borderTopRightRadius: 0, borderBottomRightRadius: 0, alignItems: 'center' }}>
                                            <CheckIcon style={{ height: '1rem', width: '1.3rem', color: item.disable ? "grey" : "white" }} />
                                        </Grid>
                                        <Grid sx={{ padding: "12px" }}>
                                            <Typography variant='titleNormal' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1 }}>{item.name} {item.subtitle ? "(" + item.subtitle + ")" : null}</Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'sixth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ ...commonCardStyles }}>
                    <CardHeader title={"Investment size"} action={() => { navigate(`/profile/edit_profile?page=1`) }} />
                    <Grid
                        container
                        xs={12}
                        sx={{
                            overflow: "hidden",
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, padding: "0.4rem", borderRadius: "8px", width: '40%' }} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant='title' sx={{ fontSize: "large", textAlign: 'center', marginInline: 'auto', paddingX: '0.2rem' }}>
                                {convertCurrencyAmountRange(personalDetails?.min, personalDetails?.investment_amount_type, 0)}
                            </Typography>
                        </Grid>
                        {/* <div style={{ flexGrow: 1, height: 0, borderTop: "0.1rem", borderBottomWidth: 0, borderColor: "#000", borderStyle: 'dashed', margin: "0.2rem" }} /> */}
                        <Typography variant='title' sx={{ fontSize: "medium", textAlign: 'center', paddingX: '0.2rem', width: '10%' }}>
                            TO
                        </Typography>
                        {/* <div style={{ flexGrow: 1, height: 0, borderTop: "0.1rem", borderBottomWidth: 0, borderColor: "#000", borderStyle: 'dashed', margin: "0.2rem" }} /> */}
                        <Grid display={'flex'} sx={{ border: `1px solid ${theme.palette.customColors.g75}`, padding: "0.4rem", borderRadius: "8px", width: '40%' }} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant='title' sx={{ fontSize: "large", textAlign: 'center', marginInline: 'auto', paddingX: '0.2rem' }}>
                                {convertCurrencyAmountRange(personalDetails?.max, personalDetails?.investment_amount_type, 0)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            {/* < Grid sx={{ gridArea: 'aside', maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item >
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Preferences"}
                        action={() => { navigate(`/profile/edit_profile?page=1`) }}
                    />
                    <Typography marginBlock={1} fontWeight={600}>
                        Stages
                    </Typography>
                    <Grid container xs={12} display={'flex'} gap={1} justifyContent={"space-between"}>
                        {
                            personalDetails?.stage_info?.map(stage => {
                                return (
                                    <Grid item xs={12} sm={5.85} height={106}
                                        sx={{
                                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                                            borderRadius: "8px",
                                            overflow: "hidden"
                                        }}>
                                        <Grid item xs={12}
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            gap={"5rem"}
                                            sx={{
                                                padding: "12px 0 0 16px",
                                            }}>
                                            <Typography>{stage?.name}</Typography>
                                            {getStageIcon(stage)}
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Typography marginBlock={1} mt={2} fontWeight={600}>
                        Category
                    </Typography>
                    <Grid container xs={12} gap={1} justifyContent={"space-between"}>
                        {[{ name: "Marketplace" }, { name: "Services" }]?.map(category => (<Grid container xs={12} sm={5.85}
                            sx={{
                                border: `1px solid ${theme?.palette?.customColors?.b75}`,
                                borderRadius: "8px",
                                overflow: "hidden",
                            }}>
                            <Grid item xs={12}
                                display={"flex"}
                                justifyContent={"space-between"}
                                gap={"5rem"}
                                sx={{
                                    padding: "12px 16px",
                                }}>
                                <Typography>{category?.name}
                                </Typography>
                                {getCategoryIcon(category)}
                            </Grid>
                        </Grid>))}
                    </Grid>
                    <Typography marginBlock={1} mt={2} fontWeight={600}>
                        Sub Sector
                    </Typography>
                    <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', padding: 0, paddingBottom: 1, marginTop: 0, justifyContent: 'space-between' }} gap={2}>
                        {Array.isArray(startup_sub_categories) && startup_sub_categories?.map((subcategory, index) => {
                            return (
                                <Grid style={{ display: 'grid' }} xs={12}>
                                    <span style={{ fontWeight: 500 }}>{subcategory.name}</span>
                                    <p style={{ fontSize: '0.8rem' }}>{subcategory?.description}</p>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Typography marginBlock={1} mt={2} fontWeight={600}>
                        Investment Amount Range
                    </Typography>
                    <Typography>{personalDetails?.investment_size}</Typography>
                </Card>
            </Grid > */}
            {
                // (personalDetails?.investment_count > 0 && personalDetails?.investment_count != (12)) && <Grid sx={{ gridArea: 'third', maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                //     <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                //         <CardHeader title={"Portfolio"}
                //             action={() => { navigate(`/profile/edit_profile?page=2`) }}
                //         />
                //         <Grid
                //             container
                //             xs={12}
                //             sx={{
                //                 border: `1px solid ${theme?.palette?.customColors?.b75}`,
                //                 borderRadius: "8px",
                //                 overflow: "hidden",
                //             }}
                //         >
                //             <Table>
                //                 <TableHead>
                //                     <TableRow sx={{
                //                         padding: "12px 16px",
                //                         backgroundColor:
                //                             theme?.palette?.customColors?.g200
                //                     }}>
                //                         <TableCell>
                //                             <Typography textAlign={'left'} fontWeight={400} color={theme?.palette?.customColors?.g50}>Startup Name</Typography>
                //                         </TableCell>
                //                         <TableCell>
                //                             <Typography textAlign={'right'} fontWeight={400} color={theme?.palette?.customColors?.g50}>
                //                                 Startup URL
                //                             </Typography>
                //                         </TableCell>
                //                     </TableRow>
                //                 </TableHead>
                //                 <TableBody>
                //                     {personalDetails?.startups?.length > 0 && personalDetails?.startups?.map((startup, index) => {
                //                         return <TableRow
                //                             item
                //                             key={index}
                //                             xs={12}
                //                             display={"flex"}
                //                             justifyContent={"space-between"}
                //                             sx={{
                //                                 padding: "12px 16px",
                //                                 backgroundColor:
                //                                     index % 2 === 0 ? theme?.palette?.customColors?.b50 : '',
                //                             }}
                //                         >
                //                             <TableCell>
                //                                 <Typography textAlign={'left'} fontWeight={500} color={theme?.palette?.customColors?.g300}>
                //                                     {startup?.startup_name}
                //                                 </Typography>
                //                             </TableCell>
                //                             <TableCell>
                //                                 <Typography textAlign={'right'} sx={{ textDecoration: 'underline', cursor: 'pointer' }} fontWeight={400} color={theme?.palette?.customColors?.indigo600} onClick={() => { window.open(startup?.startup_url) }}>
                //                                     {startup?.startup_url}
                //                                 </Typography>
                //                             </TableCell>
                //                         </TableRow>
                //                     })}

                //                     {personalDetails?.startups?.length == 0 && <TableRow
                //                         item
                //                         xs={12}
                //                         display={"flex"}
                //                         justifyContent={"space-between"}
                //                         sx={{
                //                             padding: "12px 16px",
                //                             backgroundColor:
                //                                 theme?.palette?.customColors?.b50,
                //                         }}
                //                     >
                //                         <TableCell colSpan={2} marginInline={"auto"} sx={{ textAlign: 'center' }}>No Invested Startups</TableCell>
                //                     </TableRow>}
                //                 </TableBody>
                //             </Table>
                //         </Grid>
                //     </Card>
                // </Grid>
            }
        </Grid>
    )
}

export default PersonalDetails