import React from "react";
import ProfileInformation from "./ProfileInformation";
import StartupInformation from "./StartupInformation";
import { Form, FormikProvider, useFormik } from "formik";
import { AddStartup, FetchAllCountries, FetchAllSectors, FetchProfileDetails, FetchStartupDetails, SavePersonalInfo, SaveProfileImage, SaveStartupInfo, SendWhatsappOTP } from "../../api";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import CustomToast from "../../ui-components/CustomToast";
import ModalComponent from "../../ui-components/ModalComponent";
import Wrapper from "./Wrapper";
import { Box, CircularProgress, Modal } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useConfig } from "../../dynamicConfiguration/configContext";
import { handleFormSubmit, isRequired, linkedInTestURL } from "../../utils";

const countryCodes = [
  { code: "+1", label: "US" },
  { code: "+91", label: "IN" },
];

const StartupOnboarding = () => {
  const { config } = useConfig()
  const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: '' })
  const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = FetchProfileDetails()
  const { data: startup_sectors, isRefetching: isRefetchingSectors, isLoading: isLoadingSectors, refetch: refetchSectors } = FetchAllSectors()
  // let stepIndex = new URLSearchParams(window.location.search).get("page");
  let stepIndex = Cookies.get("step");
  const [isSectorAgnostic, setIsSectorAgnostic] = React.useState(false);
  const [isBusinessModelSelectAll, setIsBusinessModelSelectAll] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams()
  const [progress, setProgress] = React.useState(1);
  const [currentStep, setCurrentStep] = React.useState(() => {
    const step = Number(stepIndex) || 1;
    return step > 11 ? 1 : step;
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    mutateAsync: AddStartupAsync,
    status: AddStartupStatus,
    error: AddStartupError,
    isLoading: isUpdating,
    data: AddStartupResponse,
  } = AddStartup({ setProgress });
  const {
    mutateAsync: SavePersonalInfoAsync,
    status: savePersonalInfoStatus,
    error: savePersonalInfoError,
    isLoading: isUpdatingsavePersonalInfo,
    data: savePersonalInfoResponse,
  } = SavePersonalInfo();
  const {
    mutateAsync: SaveProfileImageAsync,
    status: saveProfileImageStatus,
    error: saveProfileImageError,
    isLoading: isUpdatingsaveProfileImage,
    data: saveProfileImageResponse,
  } = SaveProfileImage();
  const {
    mutateAsync: SaveStartupInfoAsync,
    status: saveStartupInfoStatus,
    error: saveStartupInfoError,
    isLoading: isUpdatingsaveStartupInfo,
    data: saveStartupInfoResponse,
  } = SaveStartupInfo();
  const {
    mutateAsync: SendWhatsappOTPAsync,
    status: sendWhatsappOTPStatus,
    error: sendWhatsappOTPError,
    isLoading: isUpdatingSendWhatsappOTP,
    data: sendWhatsappOTPResponse,
  } = SendWhatsappOTP();

  const isValidDate = (day, month, year) => {
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };

  const nameValidations = {
    title: isRequired(config?.validationFields, "title") ? Yup.string(config?.validationFields?.title?.error).required(config?.validationFields?.title?.error).trim() : Yup.string(),
    first_name: isRequired(config?.validationFields, "first_name") ? Yup.string(config?.validationFields?.first_name?.error).required(config?.validationFields?.first_name?.error).trim() : Yup.string(),
    last_name: isRequired(config?.validationFields, "last_name") ? Yup.string(config?.validationFields?.last_name?.error).required(config?.validationFields?.last_name?.error).trim() : Yup.string(),
    dd:
      // isRequired(config?.validationFields, "dob") ?
      Yup.string()
        // .required(config?.validationFields?.dob?.dateError)
        .matches(/^(0[1-9]|[12][0-9]|3[01])$/, config?.validationFields?.dob?.dateError)
    // : Yup.string()
    ,
    mm:
      // isRequired(config?.validationFields, "dob") ?
      Yup.string()
        // .required(config?.validationFields?.dob?.monthError)
        .matches(/^(0[1-9]|1[0-2])$/, config?.validationFields?.dob?.monthError)
    // : Yup.string()
    ,
    yyyy:
      // isRequired(config?.validationFields, "dob") ?
      Yup.number()
        // .required(config?.validationFields?.dob?.yearError)
        .min(1900, config?.validationFields?.dob?.yearError)
        .max(new Date().getFullYear(), config?.validationFields?.dob?.futureError)
    // : Yup.number()
    ,
  }



  const profileImageValidations = {
    custom_image_url: Yup.mixed()
  // .nullable()
  .test('file-or-url', config?.validationFields?.profile_pic?.error, function (value) {
    const { path, createError } = this; // `this` gives access to the path and createError function
    // If the value is a file, validate its type and size
    if (value && value instanceof File) {
      const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      // Check file type
      if (!validFileTypes.includes(value.type)) {
        return createError({
          path,
          message: 'Invalid file type. Only JPG, PNG, or JPEG are allowed.',
        });
      }

      // Check file size (2MB max)
      if (value.size > 2000000) {  // 2MB
        return createError({
          path,
          message: 'File size exceeds 2MB.',
        });
      }

      return true; // If file type and size are valid
    }

    // If the value is a string (URL), validate it as a URL
    if (typeof value === 'string') {
      if (!Yup.string().url().isValidSync(value)) {
        return createError({
          path,
          message: 'Invalid URL format.',
        });
      }
      return true; // If URL is valid
    }

    return true; // For other cases, if there's no error, it's valid
  }).required(config?.validationFields?.profile_pic?.error)
    ,
    // avatar_selected: Yup.string().nullable()
  }

  const countryValidations = {
    country_id: isRequired(config?.validationFields, "country") ? Yup.string(config?.validationFields?.country?.error).required(config?.validationFields?.country?.error).trim() : Yup.string(),
  }

  const stateValidations = {
    state_id: isRequired(config?.validationFields, "state") ? Yup.string(config?.validationFields?.state?.error).required(config?.validationFields?.state?.error).trim() : Yup.string(),
    city_id: isRequired(config?.validationFields, "city") ? Yup.string(config?.validationFields?.city?.error).required(config?.validationFields?.city?.error).trim() : Yup.string(),
  }

  const sectorValidations = {
    // category_id: Yup.string("Please select your preferred category").required("Please select your preferred category").trim(),
    category_id: Yup.array()
      .of(Yup.string().trim())
      .min(1, "Please select at least one category")
      .required("Please select your preferred category"),
    sector_id: isSectorAgnostic ? Yup.array().nullable() : Yup.array().min(1, "Please select your sub sector").required("Please select your sub sector"),
    business_model_id: isBusinessModelSelectAll ? Yup.array().nullable() : Yup.array().required("Business Model is required.").min(1, "Business Model is required."),

  }

  const sizeValidations = {
    min: isRequired(config?.validationFields, "size") ? Yup.string(config?.validationFields?.size?.error).required(config?.validationFields?.size?.error) : Yup.string(),
    max: isRequired(config?.validationFields, "size") ? Yup.string(config?.validationFields?.size?.error).required(config?.validationFields?.size?.error) : Yup.string(),
  }

  const stageValidations = {
    stage_id: isRequired(config?.validationFields, "stages") ? Yup.array().min(1, config?.validationFields?.stages?.error).required(config?.validationFields?.stages?.error) : Yup.array(),
  }

  const investmentCountValidations = {
    // investment_count: isRequired(config?.validationFields, "investment_count") ? Yup.string(config?.validationFields?.investment_count?.error).required(config?.validationFields?.investment_count?.error).trim() : Yup.string(),
  }

  const investmentValidations = {
    // startups: isRequired(config?.validationFields, "investments") ? Yup.array(
    //   Yup.object({
    //     startup_name: Yup.string(config?.validationFields?.startup_name?.error).required(config?.validationFields?.startup_name?.error).trim(),
    //     startup_url: Yup.string(config?.validationFields?.startup_website_url?.error).required(config?.validationFields?.startup_website_url?.error).trim()
    //   })
    // ) : Yup.string(),
  }

  const whatsappValidations = {
    whatsapp_number: isRequired(config?.validationFields, "mobile_number") ? Yup.string(config?.validationFields?.mobile_number?.error).required(config?.validationFields?.mobile_number?.error).trim() : Yup.string(),
  }

  const linkedInValidations = {
    profile_url: isRequired(config?.validationFields, "linkedIn_profile_url") ? Yup.string(config?.validationFields?.linkedIn_profile_url?.error).required(config?.validationFields?.linkedIn_profile_url?.error).trim().matches(linkedInTestURL, config?.validationFields?.startup_website_url?.formaterror) : Yup.string(),
  }

  const getValidations = () => {
    let validations = Yup.object().shape({})
    switch (currentStep) {
      case 1: {
        validations = Yup.object().shape(nameValidations).test('is-valid-date', config?.validationFields?.dob?.error, function (value) {
          const { dd, mm, yyyy } = value || {};
          if (!isValidDate(parseInt(dd), parseInt(mm), yyyy)) {
            return false; // Invalid date
          }

          // Check if the date is in the future
          const inputDate = new Date(yyyy, mm - 1, dd);
          const today = new Date();
          // console.log("inputDate", inputDate)
          today.setHours(0, 0, 0, 0); // Set to the start of the day
          return inputDate <= today; // Allow dates that are today or earlier
        });
        break
      }
      case 2: {
        validations = Yup.object().shape(profileImageValidations).test('at-least-one', config?.validationFields?.profile_pic?.error, function (values) {
          const { custom_image_url, avatar_selected } = values;
          // return custom_image_url || avatar_selected;
          return custom_image_url;
        });
        break
      }
      case 3: {
        validations = Yup.object().shape(countryValidations)
        break
      }
      case 4: {
        validations = Yup.object().shape(stateValidations)
        break
      }
      case 5: {
        validations = Yup.object().shape(sectorValidations)
        break
      }
      case 6: {
        validations = Yup.object().shape(sizeValidations)
        break
      }
      case 7: {
        validations = Yup.object().shape(stageValidations)
        break
      }
      case 8: {
        validations = Yup.object().shape(investmentCountValidations)
        break
      }
      // case 9: {
      //   validations = Yup.object().shape(investmentValidations)
      //   break
      // }
      case 9: {
        validations = Yup.object().shape(whatsappValidations)
        break
      }
      case 10: {
        validations = Yup.object().shape(linkedInValidations)
        break
      }
    }
    // console.log("validations", validations)
    return validations
  }

  // const validationSchema = Yup.object().shape({ ...validationObj })
  const formik = useFormik({
    initialValues: !isProfileDetailsLoading && !isLoadingCountries && profileDetailsData ? profileDetailsData.data : {
      // step 1
      first_name: '',
      last_name: '',
      title: '',
      dd: '',
      mm: '',
      yyyy: '',
      custom_image_url: null,
      avatar_selected: null,
      country_id: null,
      state_id: null,
      city_id: null,
      category_id: [],
      sector_id: [],
      business_model_id: [],
      investment_size: null,
      min: null,
      max: null,
      investment_amount_type: null,
      stage_id: [],
      investment_count: null,
      startups: [],
      enabled: false,
      whatsapp_number: null,
      profile_url: null,
      public_url: '',
      linkedin_public_profile: false,
      step: 1
    },
    validationSchema: getValidations,
    // validate: (values) => { console.log(values, "valueseferfe"); console.log(formik.errors, "err") },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    enableReinitialize: true,
    onSubmit: async (values, { setErrors }) => {
      const formData = new FormData();
      let request = {}
      let requestArray = []

      switch (currentStep) {
        case 1:
          let dob = null;
          if (values.mm != null && values.dd != null && values.yyyy && values.yyyy?.length != 0) {
            let combinedDateString = values.mm + "-" + values.dd + "-" + values.yyyy
            let combinedDate = moment(combinedDateString, "MM-DD-YYYY")
            // if (combinedDate.isAfter(new Date())) {
            //   setErrors({ yyyy: config?.validationFields?.dob?.futureError })
            // } else {
            //   setErrors(null)
            // }
            dob = combinedDate
          }
          request = {
            ...request, ...{
              title: values.title,
              first_name: values.first_name,
              last_name: values.last_name,
              dob: dob,
              bio: values.bio,
            }
          }
          break;

        case 2:
          if (values.custom_image_url != null) {
            if (typeof values.custom_image_url != 'string') {
              formData.append("profile_picture", values.custom_image_url);
            }
            else{
              formData.append("is_profile_image", true);
            }
          }
          // } else {
          //   formData.append("avatar_id", values.avatar_selected);
          // }
          break;

        case 3:
          request = {
            ...request, ...{
              country_id: values.country_id,
            }
          }
          break;

        case 4:
          request = {
            ...request, ...{
              state_id: values.state_id,
              city_id: values.city_id,
            }
          }
          break;

        case 5:
          request = {
            ...request, ...{
              category_id: values.category_id,
              sector_ids: values.sector_id,
              sector_type: values.sector_id?.length > 0 ? "Multiple" : "All",
              business_model_id: values.business_model_id,
              business_model_type:values.business_model_id?.length > 0 ? "Multiple" : "All",
            }
          }
          break;

        case 6:
          request = {
            ...request, ...{
              investment_size: values.investment_size,
              min: values.min,
              max: values.max,
              investment_amount_type: values.investment_amount_type
            }
          }
          // formData.append("stage_id", values.stage_id);
          break;

        case 7:
          request = {
            ...request, ...{
              stage_ids: values.stage_id,
            }
          }
          break;

        case 8:
          request = {
            ...request, ...{
              investment_count: values.investment_count,
            }
          }
          break;

        // case 9:
        //   let startupData = values?.startups
        //   startupData = startupData?.filter(item => item.startup_name?.trim()?.length > 0 && item.startup_url?.trim()?.length > 0)
        //   requestArray = [...startupData]
        //   break;

        case 9:
          request = { ...request, ...{ mobile_number: values.whatsapp_number } }
          break;

        case 10:
          request = {
            ...request, ...{
              linkedin_profile_url: values?.profile_url,
              is_discoverd_publicly: values?.linkedin_public_profile,
              is_profile_completed: true
            }
          }
          break;
        default:
          break;
      }

      if (currentStep >= values?.step || values?.step == undefined) {
        formData.append('step', currentStep + 1)
        if (currentStep != 9) {
          request = { ...request, ...{ step: currentStep + 1 } }
        }
      }
      if (currentStep == 2) {
        SaveProfileImageAsync(formData).catch(err => {
          // console.log(err?.response?.data?.message, "response herer")

          toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            duration: 4000,
            position: "top-right",
            style: {
              padding: "15px 30px",
            },
          });
        });
      }
      //  else if (currentStep == 9) {
      //   SaveStartupInfoAsync(requestArray).catch(err => {
          

      //     toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
      //       duration: 4000,
      //       position: "top-right",
      //       style: {
      //         padding: "15px 30px",
      //       },
      //     });
      //   });
      // } 
      else if (currentStep == 9) {
        SendWhatsappOTPAsync(request).catch(err => {
          // console.log(err?.response?.data?.message, "response herer")

          toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            duration: 4000,
            position: "top-right",
            style: {
              padding: "15px 30px",
            },
          });
        });
      } else {
        SavePersonalInfoAsync(request).catch(err => {
          // console.log(err?.response?.data?.message, "response herer")

          toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            duration: 4000,
            position: "top-right",
            style: {
              padding: "15px 30px",
            },
          });
        });
      }
    },
  });

  // console.log(formik.values, formik.errors, "ddd")

  React.useEffect(() => {
    // console.log("savePersonalInfoResponse", savePersonalInfoResponse)
    if (savePersonalInfoResponse && savePersonalInfoResponse?.data?.statusCode == 200) {
      refetchProfileDetails()
      if (currentStep == 10) {
        // console.log(savePersonalInfoResponse,"res")
        // Cookies.set("is_profile_complete", savePersonalInfoResponse?.data?.data?.is_profile_completed ? savePersonalInfoResponse?.data?.data?.is_profile_completed : false);
        Cookies.set("is_profile_complete", true);
        navigate("/welcome");
      } else if (currentStep == 8) {
        if (formik?.values?.investment_count == 12) {
          setCurrentStep(currentStep + 2);
          // setSearchParams({ page: currentStep + 2 });
          Cookies.set("step", currentStep + 2)
        } else {
          setCurrentStep(currentStep + 1);
          // setSearchParams({ page: currentStep + 1 });
          Cookies.set("step", currentStep + 1)
        }
      }
      else {
        // refetchStartupDetails()

        setCurrentStep(currentStep + 1);
        // setSearchParams({ page: currentStep + 1 });
        Cookies.set("step", currentStep + 1)
      }
    }
    window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  }, [savePersonalInfoResponse?.data?.statusCode]);

  React.useEffect(() => {
    // console.log("saveProfileImageResponse", saveProfileImageResponse)
    if (saveProfileImageResponse && saveProfileImageResponse?.data?.statusCode == 200) {
      refetchProfileDetails()
      if (currentStep == 10) {
        // console.log(saveProfileImageResponse,"res")
        Cookies.set("is_profile_complete", saveProfileImageResponse?.data?.data?.is_profile_completed);
        navigate("/welcome");
      }
      else {
        // refetchStartupDetails()

        setCurrentStep(currentStep + 1);
        // setSearchParams({ page: currentStep + 1 });
        Cookies.set("step", currentStep + 1)
      }
    }
    window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  }, [saveProfileImageResponse?.data?.statusCode]);

  React.useEffect(() => {
    // console.log("saveProfileImageResponse", saveProfileImageResponse)
    if (saveStartupInfoResponse && saveStartupInfoResponse?.data?.statusCode == 200) {
      refetchProfileDetails()
      setCurrentStep(currentStep + 1);
      // setSearchParams({ page: currentStep + 1 });
      Cookies.set("step", currentStep + 1)
    }
    window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  }, [saveStartupInfoResponse?.data?.statusCode]);

  // React.useEffect(() => {
  //   if (sendWhatsappOTPResponse && sendWhatsappOTPResponse?.data?.statusCode == 200) {
  // refetchProfileDetails()
  // setCurrentStep(currentStep + 1);
  // setSearchParams({ page: currentStep + 1 });
  //   }
  //   window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  // }, [sendWhatsappOTPResponse?.data?.statusCode]);

  React.useEffect(() => {
    !isProfileDetailsLoading && profileDetailsData?.data?.sector_type === "All" && setIsSectorAgnostic(true) && profileDetailsData?.data?.business_model_type === "All" && setIsBusinessModelSelectAll(true)
}, [isProfileDetailsLoading])

  return (
    <>
      <Modal open={isProfileDetailsLoading || isLoadingCountries || isLoadingSectors}>
        <Wrapper className="skeleton" style={{
          display: 'grid', height: '100vh',
          width: "100vw",
          placeItems: 'center'
        }}>
          {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
        </Wrapper>
      </Modal>
      <FormikProvider value={formik}>
        <Form onSubmit={(e) => handleFormSubmit(e,formik)} onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            //  handleFormSubmit(e,formik);
          }
        }} style={{ height: '100%', display: 'grid', alignContent: 'space-between' }}>
          <ProfileInformation
            progress={progress}
            setProgress={setProgress}
            formik={formik}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isLoading={isProfileDetailsLoading || isUpdatingsaveStartupInfo}
            isLoadingDetails={isProfileDetailsLoading}
            isUpdating={isUpdatingsavePersonalInfo || isUpdatingsaveProfileImage}
            resend={SendWhatsappOTPAsync}
            refetchProfileDetails={refetchProfileDetails}
            isSectorAgnostic={isSectorAgnostic}
            setIsSectorAgnostic={setIsSectorAgnostic}
            isBusinessModelSelectAll={isBusinessModelSelectAll}
            setIsBusinessModelSelectAll={setIsBusinessModelSelectAll}
          />
        </Form>
      </FormikProvider>
    </>
  );
};

export default StartupOnboarding;
