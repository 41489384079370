import { useTheme } from "@emotion/react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { B300 } from "../../../ui-components/CustomButton";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../../ui-components/CustomInputField";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const InviteInvestorPopup = ({ handleClose, handleSubmit }) => {
  const theme = useTheme();
  const { id: round_id } = useParams();
  const startup_id = useSelector((state) => state.startupDetails.data)?._id;

  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      // console.log(
      //   {
      //     invitations: [
      //       {
      //         startup_id: startup_id,
      //         startup_round_id: round_id,
      //         message: values?.message,
      //       },
      //     ],
      //     email: values?.email,
      //   },
      //   "values"
      // );
      // handleSubmit(values);
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "8px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Invite Investors
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Simply add their email addresses and send the invitation.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomInputField
                name="email"
                label={"INVESTOR EMAIL"}
                placeholder={"Enter investor email"}
                value={formik.values.email}
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value);
                }}
                sx={{ marginBottom: 1 }}
              />
              <CustomInputField
                name="message"
                label={<> MESSAGE <span style={{color: theme?.palette?.customColors?.g100}}>(Optional)</span></>}
                placeholder={"Enter message"}
                value={formik.values.message}
                onChange={(e) => {
                  formik.setFieldValue("message", e.target.value);
                }}
                multiline
                minRows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
              >
                Invite
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default InviteInvestorPopup;
