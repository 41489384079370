import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, CircularProgress } from '@mui/material';
import { B300, WHITEBG } from '../CustomButton';
import { useTheme } from '@emotion/react';
import CustomInputField from 'ui-components/CustomInputField';

const ConfirmationPopup = ({ open, handleClose, handleSubmission, heading, subheading, ActionText, isLoading, optional = true, currentRow }) => {
    const theme = useTheme();
    const [message, setMessage] = React.useState("");
    const [hasError, setHasError] = React.useState(false)

    return (
        <Dialog open={open} onClose={() => { handleClose(); setMessage(''); setHasError(false) }} aria-labelledby="delete-confirmation-dialog" sx={{ minWidth: 300 }}>
            <DialogTitle id="delete-confirmation-dialog" sx={{ textAlign: 'center', fontWeight: 700, fontSize: '1.5rem', color: theme.palette.customColors.g300, paddingBottom: '0.5rem' }}>
                {heading ? heading : "Confirm Deletion"}
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
                <Grid container justifyContent="center">
                    <Typography sx={{ fontSize: '1rem', color: theme.palette.customColors.g200, textAlign: 'center', marginBottom: 3 }}>
                        {subheading ? subheading : 'Are you sure you want to delete this item? This action cannot be undone.'}
                    </Typography>
                    <CustomInputField
                        name="message"
                        label={<> MESSAGE
                            <span style={{ color: theme?.palette?.customColors?.g100 }}>{optional && ` (Optional)`}</span>
                        </>}
                        placeholder={"Enter message"}
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        sx={{ marginBottom: 0 }}
                        multiline
                        minRows={3}
                        errorMessage='Please enter a message'
                        hasError={hasError}
                    />
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', padding: '1rem 1.5rem' }}>
                <WHITEBG onClick={handleClose} fullWidth sx={{ padding: '0.75rem 1.25rem', fontSize: '1rem', marginRight: '1rem', backgroundColor: theme.palette.customColors.grey, borderWidth: '1px !important' }}>
                    Cancel
                </WHITEBG>
                <B300
                    onClick={() => {
                        if (optional) {
                            setHasError(false)
                            handleSubmission({ row: currentRow, message })
                            setMessage('');
                        }
                        else if (!optional && message?.length > 0) {
                            setHasError(false)
                            handleSubmission({ row: currentRow, message })
                            setMessage('');
                        }
                        else if (!optional && message?.length === 0) {
                            setHasError(true)
                        }
                        else {
                            setHasError(true)
                        }
                    }}
                    fullWidth
                    sx={{ padding: '0.75rem 1.25rem', fontSize: '1rem', backgroundColor: theme.palette.customColors.red, height: "52px" }}
                    disabled={isLoading && hasError}
                >
                    {isLoading ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.2rem'} /> : ActionText ? ActionText : "Delete"}
                </B300>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationPopup;
