import React from 'react';
import AuthWrapper1 from './AuthWrapper1';
import googleIcon from '../../assets/google_icon.png';
import linkedInIcon from '../../assets/linkedin_icon.png';
import appleIcon from '../../assets/apple_icon.png';
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import CustomBackgroundComponent from '../../ui-components/CustomBackgroundComponent';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage';
import { useTheme } from '@emotion/react';
import CustomInputField from '../../ui-components/CustomInputField';
import { B300 as CustomButton, WHITEBG } from '../../ui-components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { LoginViaEmail } from '../../api';
import toast from 'react-hot-toast';
import CustomToast from '../../ui-components/CustomToast';
import Cookies from "js-cookie";
import { auth, provider, signInWithPopup, signInWithRedirect, signOut } from "../../firebase";
import * as jwt_decode from 'jwt-decode';
import LinkedinLogin from './LinkedinLogin';
import { useConfig } from '../../dynamicConfiguration/configContext';

const AuthLogin = () => {

  const { config } = useConfig()
  const theme = useTheme();
  const navigate = useNavigate();
  const currentIP = window.location.host;
  const currentLOcation = window.location.pathname
  const [userData, setUserData] = React.useState(null);
  const [loginMethod, setLoginMethod] = React.useState("email");
  const { mutateAsync: loginViaEmailAsync, status: LoginViaEmailStatus, error: LoginViaEmailError, isLoading, data: loginViaEmailResponse } = LoginViaEmail();

  React.useEffect(() => {
    window.document.getElementsByTagName("body")[0].style.background = "#FFFAF5";
  }, [])

  React.useEffect(() => {
    if (loginViaEmailResponse && loginViaEmailResponse?.data?.statusCode === 200) {
      Cookies.get("email") && navigate("/verification");
      // else {
      // loginViaEmailResponse?.data?.data?.is_profile_completed == true ? navigate("/") : navigate("/onboarding");
      // }
      // console.log(loginViaEmailResponse?.data?.data?.is_profile_completed,loginViaEmailResponse?.data?.data?.is_profile_complete == "true")

    }
    if (loginViaEmailResponse && loginViaEmailResponse?.data?.statusCode !== 200) {
      toast.error(<CustomToast message={loginViaEmailResponse?.data.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />, {
        duration: 4000,
        position: 'top-right',
        style: {
          padding: "15px 30px"
        }
      });
    }
  }, [loginViaEmailResponse?.data?.statusCode]);

  const [emailValue, setEmailValue] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const handleLogin = () => {
    setLoginMethod("email");
    loginViaEmailAsync({
      "email": emailValue,
      "devices": {
        "device_token": `${Math.random() * 10}`,
        "device_type": "web"
      },
      "last_login_ip": `${currentIP}`,
      "auth_provider": "email"
    }, {
      onSuccess: (response) => {
        Cookies.set("email", emailValue);
        Cookies.set("step", Number(response?.data?.data?.userData?.step))
      },
      onError: () => {
        setEmailError(true);
      }
    });
  };

  React.useEffect(() => {
    const initAppleSignIn = () => {
      window.AppleID.auth.init({
        clientId: 'in.backrr.startup',
        scope: "name%20email",
        redirectURI: "https://startup.backrr.in",
        state: 'SignInUserAuthenticationRequest',
        usePopup: true
      });
    };

    if (window.AppleID) {
      initAppleSignIn();
    } else {
      const script = document.createElement('script');
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
      script.onload = initAppleSignIn;
      document.body.appendChild(script);
    }
  }, []);

  const handleSignIn = async () => {
    await window.AppleID.auth.signIn().then((res) => {
      const id_token = res.authorization.id_token;
      // console.log(res, "res")
      const user = jwt_decode.jwtDecode(id_token);
      // console.log(user, "user")
      setLoginMethod("appleid");
      loginViaEmailAsync({
        "email": user?.email,
        "devices": {
          "device_token": `${Math.random() * 10}`,
          "device_type": "web"
        },
        "last_login_ip": `${currentIP}`,
        "auth_provider": "appleid"
      }, {
        onSuccess: (response) => {
          Cookies.set("jwt", response?.data?.data?.userData?.token);
          Cookies.set("is_profile_complete", response?.data?.data?.is_profile_completed);
          if (response?.data?.data?.is_profile_completed == true) {
            Cookies.set("is_profile_complete", true)
            navigate("/profile");
          }
          else {
            Cookies.set("is_profile_complete", false)
            Cookies.set("step", Number(response?.data?.data?.userData?.step))
            navigate("/onboarding");
          }
        }
      });
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleSignInGoogle = async () => {
    setLoginMethod("google");
    await signOut(auth);
    await signInWithPopup(auth, provider)
      .then((result) => {
        // console.log(result, "result?.user");
        Cookies.set("email", result?.user?.email)
        Cookies.set("userDetails", JSON.stringify({ first_name: result?._tokenResponse?.firstName, last_name: result?._tokenResponse?.lastName, mobile_number: result?.user?.phoneNumber }))
        loginViaEmailAsync({
          "email": result?.user?.email,
          "devices": {
            "device_token": `${Math.random() * 10}`,
            "device_type": "web"
          },
          "last_login_ip": `${currentIP}`,
          "auth_provider": "google"
        }, {
          onSuccess: (response) => {
            Cookies.set("jwt", response?.data?.data?.userData?.token);
            Cookies.set("is_profile_complete", response?.data?.data?.is_profile_completed);
            if (response?.data?.data?.userData?.is_profile_completed == true) {
              // console.log(currentLOcation,"path")
              Cookies.set("is_profile_complete", true)
              navigate(currentLOcation !== "/login" && currentLOcation !== "/verification" ? currentLOcation : "/profile");
            }
            else {
              Cookies.set("is_profile_complete", false)
              // navigate("/onboarding?page=" + Number(response?.data?.data?.step));
              navigate("/onboarding");
              // console.log(response?.data?.data, "response?.data?.data")
              Cookies.set("step", Number(response?.data?.data?.userData?.step))
            }
          }
        });
      })
      .catch((error) => {
        console.error('Error signing in:', error);
      });
  };

  const handleSubmit = () => {
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern?.test(emailValue)) {
      handleLogin();
    }
    else {
      setEmailError(true);
    }
  };

  return (
    <>
      <Grid item xs={12} display={"flex"} flexDirection={"column"} maxHeight={"736px"} gap={0}>
        <BackrrLogoImage />
        <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }} marginTop={1} textAlign={{ xs: "center", md: 'start' }}>
          Backrr for Investors
        </Typography>
        <Typography variant='bodyTextRegular' color={theme.palette.customColors.g100} marginTop={1} sx={{ textAlign: { xs: 'center', md: "start" } }}>
          Login or signup with options below
        </Typography>
        <CustomInputField sx={{ margin: 0 }} value={emailValue} onKeyDown={(e) => { if (e.key === "Enter") { handleSubmit(); } }} onChange={(event) => {
          setEmailValue(String(event.target.value)?.toLowerCase()); setEmailError(false);
        }} hasError={emailError} errorMessage={config?.validationFields?.email?.invalidError} labelStyle={{ marginTop: "1rem" }} fullWidth label={config?.validationFields?.email?.label} type="email" placeholder='' />
        <Grid display={"grid"} gap={"1rem"}>
          <CustomButton disabled={isLoading && loginMethod === "email"} sx={{ background: theme.palette.customColors.B300, borderRadius: "8px", textTransform: "none", height: '55px', fontSize: '1rem', marginTop: "1rem" }} fullWidth onClick={() => {
            handleSubmit();
          }}>
            {isLoading && loginMethod === "email" ?
              <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} />
              : config?.dynamicText.next}
          </CustomButton>
          <Grid position={'relative'}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Divider sx={{ flex: 1 }} />
            <Typography sx={{ width: "2rem", textAlign: "center" }}>or</Typography>
            <Divider sx={{ flex: 1 }} />
          </Grid>
          {config?.settings.googleLogin && <WHITEBG sx={{ border: `1px solid ${theme.palette.customColors.g75}`, height: '55px' }} onClick={() => handleSignInGoogle()} fullWidth>
            {isLoading && loginMethod === "google" ? <CircularProgress sx={{ color: "black", marginInline: 'auto' }} size={'1.4rem'} /> : <Grid display={'flex'} gap={1} flexDirection={"row"} alignItems={'center'}>
              <img src={googleIcon} width={24} height={24} />
              <Typography variant="caption" sx={{ textTransform: "none", fontWeight: 500, fontSize: '1rem' }}>
                Login via Google
              </Typography>
            </Grid>}
          </WHITEBG>}
          {config?.settings.linkedInLogin && <LinkedinLogin userData={userData} setUserData={setUserData} signin={loginViaEmailAsync} loginMethod={loginMethod} setLoginMethod={setLoginMethod} isLoading={isLoading} />}
          {/* {config?.settings.appleLogin && <WHITEBG onClick={handleSignIn} data-border="true" data-type="sign in" sx={{
            border: `1px solid ${theme.palette.customColors.g75}`, height: '55px'
          }} fullWidth> {isLoading && loginMethod === "appleid" ? <CircularProgress sx={{ color: "black", marginInline: 'auto' }} size={'1.4rem'} /> : <Grid display={'flex'} gap={1} flexDirection={"row"} alignItems={'center'}> <img src={appleIcon} width={24} height={24} /> <Typography variant="caption" sx={{ textTransform: "none", fontWeight: 500, fontSize: '1rem' }}> Login via Apple ID </Typography> </Grid>} </WHITEBG>} */}

        </Grid>
      </Grid>
    </>
  )
};

export default AuthLogin