import { useTheme } from "@emotion/react";
import { Box, styled, TextField, MenuItem } from "@mui/material";

const CustomSelect = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: theme.palette.customColors.whiteBG,
    border: `1px solid ${theme.palette.customColors.g75}`,
    borderRadius: '4px',
    padding: '0px 0px 0px',
    '&:hover': {
      backgroundColor: theme.palette.customColors.whiteBG,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.customColors.whiteBG,
      borderColor: theme.palette.customColors.g75,
    },
    '& .MuiFilledInput-input': {
      padding: '13px 15px',
      height: 'auto',
      boxSizing: 'border-box',
    },
  },
  '& .MuiPopover-root > .MuiPaper-root': {
    left: '9px',
  },
  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.customColors.g100,
    opacity: 1,
  },
}));

const configSelect = {
  select: true,
  variant: 'filled',
  fullWidth: true,
};

function SelectField({ options, label, placeholder, ...props }) {
  const theme = useTheme();
  return (
    <Box>
      <Box component="label" sx={{ color: theme.palette.customColors.g500, marginBottom: '8px', display: 'block' }}>
        {label}
      </Box>
      <CustomSelect
        {...configSelect}
        {...props}
        SelectProps={{
          displayEmpty: true,
          MenuProps: {
            style: { marginLeft: "-8px" ,marginTop: "1px" }
          },
          renderValue: (selected) => 
            !selected ? <span style={{ color: theme.palette.customColors.g100 }}>{placeholder}</span> : options.find(option => option.value === selected)?.label
        }}
        InputProps={{ disableUnderline: true }}
      >
        {options?.map((item, pos) => (
          <MenuItem key={pos} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  );
}

export default SelectField;
