import { lazy } from 'react';
import Cookies from 'js-cookie'
// project imports
// import Loadable from 'ui-component/Loadable';
// import MinimalLayout from 'layout/MinimalLayout';
import AuthLogin from '../views/pages/AuthLogin';
import AuthOTPVerification from '../views/pages/AuthOTPVerification';
import LoginScreen from '../views/pages/LoginScreen';
import OTPScreen from '../views/pages/OTPScreen';
import StartupDetail from '../views/startupDetail';
import PitchDeck from '../views/PitchDeck';
import OnboardingLayout from 'layout/MainContent/OnboardingLayout';
import { Outlet } from 'react-router-dom';

// // login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '',
    element: <OnboardingLayout> <Outlet /></OnboardingLayout>,
    children: [
        // {
        //     path: '/',
        //     element: <Login />,
        // },
        {
            path: '/',
            element: <AuthLogin />,
        },
        {
            path: '/verification',
            element: <AuthOTPVerification />,
        },
        {
            path: '*',
            element: <AuthLogin />,
        },
    ]
};

export default AuthenticationRoutes;
