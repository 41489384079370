import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid } from '@mui/material';
import { B300, WHITEBG } from '../CustomButton';
import { useTheme } from '@emotion/react';

const DeleteConfirmationDialog = ({ open, handleClose, handleSubmission, heading, subheading, ActionText }) => {
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="delete-confirmation-dialog">
            <DialogTitle id="delete-confirmation-dialog" sx={{ textAlign: 'center', fontWeight: 700, fontSize: '1.5rem', color: theme.palette.customColors.g300 }}>
                {heading ? heading : "Confirm Deletion"}
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center">
                    <Typography sx={{ fontSize: '1rem', color: theme.palette.customColors.g200, textAlign: 'center' }}>
                        {subheading ? subheading : 'Are you sure you want to delete this item? This action cannot be undone.'}
                    </Typography>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', padding: '1rem 1.5rem' }}>
                <WHITEBG onClick={handleClose} fullWidth sx={{ padding: '0.75rem 1.25rem', fontSize: '1rem', marginRight: '1rem', backgroundColor: theme.palette.customColors.grey }}>
                    Cancel
                </WHITEBG>
                <B300 onClick={handleSubmission} fullWidth sx={{ padding: '0.75rem 1.25rem', fontSize: '1rem', backgroundColor: theme.palette.customColors.red }}>
                    {ActionText ? ActionText : "Delete"}
                </B300>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;
