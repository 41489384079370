import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";

export const CreateRound = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/startup-rounds`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                'Content-Type': 'application/json'
            },
            data: payload
        })
    }, {
        // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const UploadRoundDocuments = () => {
    return useMutation(
        ({payload,_id}) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/upload-mis-reports/${_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchAllRounds = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-listing-template-all-roundss-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/list/rounds-listing`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data,'values new')
            return data?.data?.data != undefined ? data?.data?.data : []
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchRoundViewDetails = (id) => {
    return useQuery(
        ['master-listing-template-round--view-details-using-id-fetch' + id, id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedData = data?.data?.data[0];
            const savedValues = {
                // step 0
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || '',
                security_type: savedData?.security_type || "",
                // step 1
                round_highlights: savedData?.round_highlights || "",
                total_round_size: savedData?.total_round_size || "",
                round_valuation:savedData?.round_valuation | "",
                minimum_cheque_size: savedData?.minimum_cheque_size || "",
                received_termsheet: savedData?.received_termsheet || false,
                committed_funding_amount: savedData?.committed_funding_amount || "",
                currency_id: savedData?.currency_id || "",
                currency: savedData?.currency_info || "",
                // step 2
                investors: savedData?.investors || [{ name: "", email: "" }],
                // step 3
                fund_utilization_highlights: savedData?.fund_utilization_highlights || "",
                fund_allocation_percentages: savedData?.fund_allocation_percentages || [
                    { name: "", percentage: 0 },
                ],
                // step 4
                traction_highlights: savedData?.traction_highlights || "",
                key_metrics: savedData?.key_metrics || [
                    {
                        heading: "",
                        properties: [
                            { key: "", value: "" },
                            { key: "", value: "" },
                            { key: "", value: "" },
                        ],
                    },
                ],
                // step 5
                documents: savedData?.documents ||  [
                    
                ],
                // step 6
                faqs: savedData?.faqs || [
                    {
                        question: "",
                        answer: "",
                    },
                ],
                // step 7
                exit_highlights: savedData?.exit_highlights || "",
                runway_left: savedData?.runway_left || 'select_runway_left',
                monthly_net_burn: savedData?.monthly_net_burn || "",
                round_start_date: savedData?.round_start_date || null,
                round_closing_date: savedData?.round_closing_date || null,
                round_status: savedData?.round_status || "",
                ...savedData
            }
            return savedValues
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchRoundDetails = (id) => {
    return useQuery(
        ['master-listing-template-round-details-using-id-fetch' + id, id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedData = data?.data?.data[0];
            const savedValues = {
                // step 0
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || '',
                security_type: savedData?.security_type || "",
                // step 1
                round_highlights: savedData?.round_highlights || "",
                total_round_size: savedData?.total_round_size || 0,
                round_valuation: savedData?.round_valuation || 0,
                round_valuation_cap: savedData?.round_valuation || 0,
                minimum_cheque_size: savedData?.minimum_cheque_size || "",
                received_termsheet: savedData?.received_termsheet || false,
                committed_funding_amount: savedData?.committed_funding_amount || "",
                currency_id: savedData?.currency_id || "",
                // step 2
                investors: savedData?.investors ? savedData?.investors?.length > 0 ? savedData?.investors : [{ name: "", email: "" }] : [{ name: "", email: "" }],
                // step 3
                fund_utilization_highlights: savedData?.fund_utilization_highlights || "",
                fund_allocation_percentages: savedData?.fund_allocation_percentages ? savedData?.fund_allocation_percentages?.length > 0 ? savedData?.fund_allocation_percentages : [
                    { name: "", percentage: 0 },
                ] : [
                    { name: "", percentage: 0 },
                ],
                // step 4
                traction_highlights: savedData?.traction_highlights || "",
                key_metrics: savedData?.key_metrics ? savedData?.key_metrics?.length > 0 ? savedData?.key_metrics : [
                    {
                        heading: "",
                        properties: [
                            { key: "", value: "" },
                            { key: "", value: "" },
                            { key: "", value: "" },
                        ],
                    },
                ] : [
                    {
                        heading: "",
                        properties: [
                            { key: "", value: "" },
                            { key: "", value: "" },
                            { key: "", value: "" },
                        ],
                    },
                ],
                // step 5
                documents: savedData?.documents ? savedData?.documents?.length > 0 ? savedData?.documents : [
                    {
                        title: "MIS Report",
                        document: null,
                        editable: false,
                        type: "",
                    },
                ] : [{
                    title: "MIS Report",
                    document: null,
                    editable: false,
                    type: "",
                },
            ],
                // documents: savedData?.documents || [
                //     {
                //         name: "MIS Report",
                //         mis_reports: null,
                //         editable: false,
                //         type: "",
                //     },
                // ],
                // step 6
                faqs: savedData?.faqs ? savedData?.faqs?.length > 0 ? savedData?.faqs : [
                    {
                        question: "",
                        answer: "",
                    },
                ] : [
                    {
                        question: "",
                        answer: "",
                    },
                ],
                // step 7
                exit_highlights: savedData?.exit_highlights || "",
                runway_left: savedData?.runway_left || 'select_runway_left',
                monthly_net_burn: savedData?.monthly_net_burn || "",
                round_start_date: savedData?.round_start_date || null,
                round_closing_date: savedData?.round_closing_date || null,
                round_status: savedData?.round_status || "",
                // ...savedData
            }
            return savedValues
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const DeleteRoundDocument = () => {
    return useMutation(
        ({ payload, document_id }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/delete-documents/${document_id} `,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const AddInvestmentForRound = () => {
    return useMutation(
        ({payload,_id}) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/add-form`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const InviteInvestorForRound = () => {
    return useMutation(
        ({payload,_id}) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/add-form`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};