// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDv7z77dUnLmyUz8sQpoWR_HeG_rqMbPqY",
    authDomain: "backrr-saas.firebaseapp.com",
    // authDomain: "startup.backrr.in",
    projectId: "backrr-saas",
    storageBucket: "backrr-saas.appspot.com",
    messagingSenderId: "323381609013",
    appId: "1:323381609013:web:1b0fb91ecb9451d99d677c",
    measurementId: "G-P13RP5DW0S"
};

export { firebaseConfig }
