import React from 'react';
import AuthWrapper1 from './AuthWrapper1';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import CustomBackgroundComponent from '../../ui-components/CustomBackgroundComponent';
import { useTheme } from '@emotion/react';
import CustomInputField from '../../ui-components/CustomInputField';
import { B300 as CustomButton } from '../../ui-components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { LoginViaEmail, VerifyOTP } from '../../api';
import toast from 'react-hot-toast';
import CustomToast from '../../ui-components/CustomToast';
import Cookies from "js-cookie";
import loginLogo from '../../assets/login_account_tick.png';
import CustomHeaderIcon from '../../ui-components/CustomHeaderIcon';
import CustomValidationText from '../../ui-components/CustomValidationText';
import { useConfig } from '../../dynamicConfiguration/configContext';

const inputProps = {
    maxLength: 1,
    inputMode: 'numeric',
    pattern: "[0-9]*",
    style: {
        textAlign: 'center',
    },
    onKeyDown: (event) => {
        if (event.key === '-' || event.keyCode === 189) {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    },
    onInput: (event) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        event.target.value = inputValue;
    }
};

const AuthOTPVerification = () => {

    const { config } = useConfig()
    const navigate = useNavigate();
    const theme = useTheme();
    const currentIP = window.location.host;
    const [error, setError] = React.useState(null)
    const [countDown, setCountDown] = React.useState(30);
    const [otpValues, setOtpValues] = React.useState(Array(4).fill(''));
    const inputRefs = [React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null)];
    const { mutateAsync: verifyOTPAsync, isLoading: isOTPLoading, data: verifyOtpResponse } = VerifyOTP();
    const { mutateAsync: reSendingOTPAsync, isLoading: isReSendingOTP } = LoginViaEmail();

    React.useEffect(() => {
        if (otpValues?.join("")?.trim()?.length > 0) {
            if (verifyOtpResponse?.data?.statusCode === 200) {
                setTimeout(() => {
                    setError(null)
                    Cookies.set("jwt", verifyOtpResponse?.data?.data?.token);
                    Cookies.set("step", Number(verifyOtpResponse?.data?.data?.step))
                    Cookies.set("is_profile_complete", verifyOtpResponse?.data?.data?.is_profile_completed);
                    verifyOtpResponse?.data?.data?.user_role && Cookies.set("ur",verifyOtpResponse?.data?.data?.user_role);
                    setTimeout(() => {
                        navigate(verifyOtpResponse?.data?.data?.is_profile_completed ? "/profile" : "/onboarding");
                    },10)
                }, 100);
            } else if (verifyOtpResponse?.data?.statusCode !== 200) {
                // console.log(verifyOtpResponse?.data?.message, "co")
                verifyOtpResponse?.data?.message && setError(verifyOtpResponse?.data?.message || null)

            }
        }
    }, [verifyOtpResponse?.data?.statusCode, navigate]);

    const handleResendingOTP = () => {
        reSendingOTPAsync({
            email: Cookies.get("email"),
            devices: { device_token: `${Math.random() * 10}`, device_type: "web" },
            last_login_ip: `${currentIP}`,
            auth_provider: "email"
        }).then(() => {
            setCountDown(30);
        });
    };

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
        setError(null);
    };

    const handleKeyDown = (event, index) => {
        // Get the reference to the current input element
        // const currentRef = inputRefs[index];

        if (event.key === 'Backspace' && !event.target.value && index > 0) {
            inputRefs[index - 1].current.focus();
        }
        if (event.key === 'Enter') {
            handleVerifyOTP();
        }

        // Check if the arrow keys are pressed
        if (event.key === 'ArrowRight') {
            // Move focus to the next input if it's not the last one
            if (index < inputRefs.length - 1) {
                inputRefs[index + 1]?.current?.focus();
            }
        } else if (event.key === 'ArrowLeft') {
            // Move focus to the previous input if it's not the first one
            if (index > 0) {
                inputRefs[index - 1]?.current?.focus();
            }
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();

        // Get the pasted data and filter out non-numeric characters
        const pastedData = event.clipboardData
            .getData('text')
            .replace(/\D/g, '') // Remove all non-numeric characters
            .slice(0, 4); // Ensure only the first 4 characters are processed

        const newOtpValues = [...otpValues];

        pastedData.split('').forEach((char, index) => {
            if (inputRefs[index]) {
                inputRefs[index].current.value = char;
                newOtpValues[index] = char;
            }
        });

        setOtpValues(newOtpValues);
    };


    const handleVerifyOTP = () => {
        const OTP = otpValues.join('');
        if (OTP?.trim()?.length > 0) { verifyOTPAsync({ email: Cookies.get("email"), otp: OTP }); }
        else {
            setError(config?.validationFields?.otp?.error);
        }
    };

    React.useEffect(() => {
        inputRefs[0].current.focus();
    }, []);

    React.useEffect(() => {
        if (countDown > 0) {
            const timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countDown]);

    return (
        <>
            <Grid item xs={12} display={"flex"} flexDirection={"column"} maxHeight={"20rem"} gap={0}>
                <CustomHeaderIcon customIcon={loginLogo} />
                <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }} marginTop={1} textAlign={{ xs: "center", md: 'start' }}>Verify with OTP</Typography>
                <Typography variant='bodyTextRegular' color={theme.palette.customColors.g100} marginTop={1} sx={{ textAlign: { xs: 'center', md: "start" } }}>{config?.dynamicText?.otpSubheading} {Cookies.get("email")}</Typography>
                <Typography color={theme.palette.customColors.g500} variant="subtitle2" mt={"1rem"} mb={"0.5rem"} textTransform={'uppercase'}>{config?.validationFields?.otp?.label}</Typography>
                <Grid container xs={12} gap={2}>
                    {inputRefs.map((ref, index) => (
                        <Grid item flex={1} key={index}>
                            <CustomInputField
                                inputProps={inputProps}
                                required
                                sx={{ marginBottom: 0 }}
                                inputRef={ref}
                                onChange={(event) => handleInputChange(event, index)}
                                onKeyDown={(event) => handleKeyDown(event, index)}
                                onPaste={index === 0 ? handlePaste : undefined}
                            />
                        </Grid>
                    ))}
                </Grid>
                {error !== null && <span style={{
                    color: "rgb(239, 83, 80)",
                    fontWeight: "400",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.03333em",
                    textAlign: "left",
                    marginTop: "3px",
                    marginRight: "14px",
                    marginBottom: "0",
                    marginLeft: "14px",
                }}>{error}</span>}
                {isReSendingOTP ? (
                    <Typography color={theme.palette.customColors.b300} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '1rem' }} fontSize="1rem">
                        <CircularProgress sx={{ color: theme.palette.customColors.b300 }} size="1rem" /> <span>Resending OTP</span>
                    </Typography>
                ) : (
                    <Typography color={theme.palette.customColors.b300} fontSize="1rem" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline', textUnderlineOffset: '5px', marginTop: '1rem' }}>
                        <span onClick={countDown === 0 ? handleResendingOTP : undefined} style={{ color: countDown > 0 ? theme.palette.customColors.b75 : theme.palette.customColors.b300, cursor: 'pointer' }}>{config?.dynamicText?.resend}</span>
                        <span>&nbsp;{countDown > 0 && `(${countDown} Sec)`}</span>
                    </Typography>
                )}
                <CustomButton disabled={isOTPLoading} sx={{ background: theme.palette.customColors.B300, borderRadius: "8px", textTransform: "none", maxWidth: "100%", marginTop: '1rem', padding: "12px 20px", minHeight: '55px', maxHeight: '60px' }} fullWidth onClick={handleVerifyOTP}>
                    {isOTPLoading ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size="1.4rem" /> : config?.dynamicText?.otpHeading}
                </CustomButton>
            </Grid>
        </>
    );
}

export default AuthOTPVerification;
