import React from 'react';
import { Box, TextField, styled } from '@mui/material';
import theme from '../themes/theme';
import CustomValidationText from './CustomValidationText';


const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: theme.palette.customColors.whiteBG,
    border: `1px solid ${theme.palette.customColors.g75}`,
    borderRadius: '4px',
    padding: '0px 0px 0px',
    '&:hover': {
      backgroundColor: theme.palette.customColors.whiteBG,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.customColors.whiteBG,
      borderColor: theme.palette.customColors.g75,
    },
    '& .MuiFilledInput-input': {
      padding: '13px 15px', 
      height: 'auto',  
      boxSizing: 'border-box',  
    },
  },

  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.customColors.g100,
    opacity: 1,
  },
}));



const CustomInputField = ({ label, placeholder, multiline = false ,minRows = 1 , type ='text', startAdornment, endAdornment, fullWidth = true,sx, value, labelStyle = {},hasError = false, errorMessage="" ,...props  }) => {
  return (
    <Box sx={{width: `${fullWidth ? "100%" :  sx.width}`}}>
      {label != undefined && <Box component="label" sx={{ color: theme.palette.customColors.g500, marginBottom: '8px', display: 'block',fontSize:"0.875rem", ...labelStyle }}>
        {label}
      </Box>}
      <CustomTextField
        variant="filled"
        placeholder={placeholder}
        multiline={multiline}
        minRows={minRows}
        value={value}
        fullWidth
        sx={{...sx}}
        InputProps={{ disableUnderline: true , startAdornment: startAdornment, endAdornment: endAdornment}}
        type={type}
        {...props}
      />
      {hasError && <CustomValidationText value={errorMessage} />}
    </Box>
  );
};
export default CustomInputField
