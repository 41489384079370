import { useTheme } from "@emotion/react";
import { Box, Card, CircularProgress, Divider, FormControlLabel, Grid, IconButton, Paper, RadioGroup, Rating, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "assets/close_icon.svg";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "ui-components/formik/CustomInputField";
import { B300 } from "ui-components/CustomButton";
import * as Yup from "yup";
import CustomLabel from "ui-components/CustomLabel";
import CustomRadio from "ui-components/CustomRadio";
import CustomValidationText from "ui-components/CustomValidationText";
import AsyncTableListing from "ui-components/AsyncTableListing";
import { Delete } from "@mui/icons-material";
import { DeleteManageNotes, FetchManageNotes, SaveManageNotes } from "api/startups";
import CustomToast from "ui-components/CustomToast";
import toast from "react-hot-toast";
import { ReactComponent as DeleteIcon } from "../../../assets/rounds_icons/delete_icon.svg";
import DeleteConfirmationDialog from "ui-components/popups/DeleteConfirmationPopup";
import { useSelector } from "react-redux";


const columns = [
    { Header: 'Date', accessor: 'formatted_date', minWidth: 100, align: 'start', fixed: true },
    { Header: 'Notes', accessor: 'message', minWidth: 300, align: 'center'},
    { Header: 'Actions', accessor: 'actions', minWidth: 100, align: 'end' },

]
// const data = [
//     {date:"24-Nov-2024" , note: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to"},
//     {date:"24-Nov-2024" , note: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to"},
//     {date:"24-Nov-2024" , note: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to"},
//     {date:"24-Nov-2024" , note: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to"},
//     {date:"24-Nov-2024" , note: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to"},
//     {date:"24-Nov-2024" , note: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to"},
// ]
const CardHeader = ({ title, actionText, action: Actions }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"1rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
        {/* {<Actions />} */}
    </Box>
}

const ManageNotes = ({ handleClose, handleSubmit, data: startupDetail , startup_id}) => {
    // console.log(startupDetail,"hhhhhh")
    const theme = useTheme();
    const userDetails = useSelector((state) => state?.investorDetails?.data);
    // console.log(userDetails,"investor_id")
    const { data: manageNotesData, isLoading: isLoadingmanageNotesData, refetch: refetchManageNotesData } = FetchManageNotes({ investor_id: userDetails?._id,  startup_id });
    const {
        mutateAsync: saveManageNotesAsync, 
        isLoading:isSavingNotes,
       
    } = SaveManageNotes();
    const [currentRow, setCurrentRow] = React.useState(null);
    // const formatedManageData = manageNotesData?.map(item => ({
    //     formatted_date: item?.formatted_date,
    //     message: item?.message,
    //     internal_notes:item?.internal_notes,

    // }))
      const handleManageNotesSubmit = ({ row, values }) => {
        let request = {
            startup_id: row?.startup_id,
            investor_id: userDetails?._id,
            message: String(values?.message)?.trim()
        }
        saveManageNotesAsync(request).then(() => {
            refetchManageNotesData();
        })
        // handleManageNotesClose();
    }
    const {
        mutateAsync: deleteManageNotesAsync,
        isLoading: isdeletingReportDocument,
        status: deleteReportDocumentStatus,
        error: deleteReportDocumentError,
        data: deleteReportDocumentResponse
      } = DeleteManageNotes();
      
    const rowActions = ({row}) => {
        // console.log(row,"row")
        return <React.Fragment>
          <IconButton onClick={() =>{
            handleOpenDeletePopup({row: row?.original})
          }}>
            <DeleteIcon />
          </IconButton>
        </React.Fragment>
      }
      const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
      
  
      const handleOpenDeletePopup = ({row}) => {
          setOpenDeletePopup(true);
        //   console.log(row,"rrrrr")
          setCurrentRow(row)
      }
  
      const handleCloseDeletePopup = () => {
          setOpenDeletePopup(false);
          setCurrentRow(null)
      }
  
      const handleDeletion = ({values}) => {
        const payload = {
            "startup_id": startup_id ,
            "investor_id": userDetails?._id,
            "_id": currentRow?._id,
        }
        // console.log(payload , currentRow , values ,"paylodrowvalues")
        // console.log(currentRow ,"rowvalues")
        deleteManageNotesAsync(payload, {
              onSuccess: (succ) => {
                  // console.log(succ, 'succ')
                  toast.success(
                      <CustomToast message={"Notes Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                      {
                          duration: 2000,
                          position: "top-right",
                      }
                  );
                  refetchManageNotesData();
              },
              onError: (err) => {
                  toast.error(
                      <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                      {
                          duration: 2000,
                          position: "top-right",
                      }
                  );
              }
          });
          handleCloseDeletePopup();
      }
    const validations = Yup.object(
        {
            message: Yup.string().required("Notes Required"),
            
        }
    )

    const formik = useFormik({
        initialValues: {
            message: "",
        },
        validateOnMount: false,
        validationSchema: validations,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values, { setSubmitting }) => {
            handleManageNotesSubmit({ row:{startup_id}, values })
        },
    });

    return (
        
        <>
        <DeleteConfirmationDialog open={openDeletePopup} handleClose={handleCloseDeletePopup} handleSubmission={handleDeletion} subheading={"Are you sure you want to delete this notes?"}  />
        <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden'}}>
        <CardHeader title={`Manage Internal Notes for ${startupDetail?.startup_name}`}/>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid display={"grid"} container padding={"0rem"} gap={"1.5rem"}>
                        <Grid item container gap={1.5}>
                            <Grid item xs={12}>
                                <CustomInputField
                                    name={`message`}
                                    labelStyle={{ marginTop: '0rem', fontWeight: 500, fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.87) !important' }} onChange={(e) => {
                                        const value = e.target.value;
                                        if (value.length <= 250) {
                                            formik.setFieldValue("message", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
                                        }
                                        else {
                                            formik.setFieldValue("message", String(e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))?.substring(0, 250))
                                        }
                                    }} multiline minRows={4} maxRows={4} maxLength={250} fullWidth data-problem-solving={250 - formik.values?.message?.length + `${(250 - formik.values?.message?.length) > 1 ? " characters" : " character"} left`}
                                    sx={{
                                        "::after": {
                                            content: 'attr(data-problem-solving)',
                                            position: "absolute",
                                            bottom: formik?.errors?.message ? 30 : 5,
                                            // bottom: 5,
                                            right: 5,
                                            background: 'white',
                                            color: 'graytext',
                                            padding: "0 5px",
                                            fontSize: "0.75rem"
                                        },
                                        marginBottom: 0
                                    }}
                                    value={formik.values?.message}
                                    placeholder=""
                                />

                            </Grid>

                            <Grid item xs={12}>
                            <B300

                                type="submit"
                                // disabled
                                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" , height:"54px" , width:'77px' }}
                               
                            >
                                { isSavingNotes
                                ?
                                <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> 
                                : "Save"}
                            </B300>
                        
                            </Grid>
                            <Divider sx={{ width: '100%' ,marginBottom:2, marginTop:2 }}  />
                            <Box width={"100%"} height="auto" maxWidth={"100%"} overflow={"hidden"}>
                                    <AsyncTableListing
                                        columns={columns}
                                        // data={[]}
                                        tabValue={[]}
                                        data={manageNotesData}
                                        // title={"Investors"}
                                        // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
                                        // actions={Actions}
                                        RowActions={rowActions}
                                        pagination={false}
                                        sx={{ borderRadius: '8px', border: `1px solid ${theme.palette.customColors.b50}` }}
                                        // refetchListing={refetchListing}
                                    />
                                </Box>
                        </Grid>
                    </Grid>
                </form>
            </FormikProvider>
        </Card>
        </>
    );
};

export default ManageNotes;
