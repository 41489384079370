import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import investorDetailsReducer from './investorDetailsReducer';
import roleDetailsReducer from './roleDetailsReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    investorDetails: investorDetailsReducer,
    roleDetails: roleDetailsReducer

});

export default reducer;
