import { useTheme } from "@emotion/react";
import { Autocomplete, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { B300 } from "../../../ui-components/CustomButton";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../../ui-components/CustomInputField";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AutoComplete } from "rsuite";
import { FetchAllCategories, FetchAllStages, FetchAllSubCategories } from "api/utils";

const FilterPopup = ({ queryParams, setQueryParams, handleClose, handleSubmit, stage, category, sector, handleReset, setStage, setCategory, setSector }) => {
  const theme = useTheme();
  const { id: round_id } = useParams();
  const startup_id = useSelector((state) => state.investorDetails.data)?._id;
  const { data: startup_stages, isRefetching: isRefetchingAllStages, isLoading: isLoadingAllStages, refetch: refetchAllStages } = FetchAllStages();
  const { data: startup_categories, isRefetching: isRefetchingAllCategories, isLoading: isLoadingAllCategories, refetch: refetchAllCategories } = FetchAllCategories();
  const { data: startup_sub_categories, isRefetching: isRefetchingAllSubcategories, isLoading: isLoadingAllSubcategories, refetch: refetchAllSubcategories } = FetchAllSubCategories({ category: "Services" });

  const formik = useFormik({
    initialValues: {
      ...queryParams
    },
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      // console.log(
      //   {
      //     invitations: [
      //       {
      //         startup_id: startup_id,
      //         startup_round_id: round_id,
      //         message: values?.message,
      //       },
      //     ],
      //     email: values?.email,
      //   },
      //   "values"
      // // );
      // handleSubmit({query});
      handleSubmit(values)
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "8px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Filter
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Simply add their email addresses and send the invitation.
              </Typography> */}

            </Grid>
            <Grid xs={12} item container columnSpacing={2} rowSpacing={1} justifyContent={"space-between"}>
              {/* <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  options={[]}
                  fullWidth
                  renderInput={(params) => <CustomInputField variant={"outlined"} {...params} sx={{ marginBottom: 0 }} label="COUNTRY" />}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  options={[]}
                  fullWidth
                  renderInput={(params) => <CustomInputField variant={"outlined"} {...params} sx={{ marginBottom: 0 }} label="STATE" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  options={[]}
                  fullWidth
                  renderInput={(params) => <CustomInputField variant={"outlined"} {...params} sx={{ marginBottom: 0 }} label="City" />}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  value={stage}
                  options={startup_stages && startup_stages?.length > 0 ? startup_stages?.map(item => ({ label: item?.name, value: item?._id })) : []}
                  fullWidth
                  autoHighlight
                  onChange={(e, newValue) => { formik.setValues({ ...formik.values, stage: newValue?.label }); setStage(newValue) }}
                  renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Stage"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  value={category}
                  options={startup_categories && startup_categories?.length > 0 ? startup_categories?.map(item => ({ label: item?.name, value: item?._id })) : []}
                  fullWidth
                  autoHighlight
                  onChange={(e, newValue) => { formik.setValues({ ...formik.values, category: newValue?.label }); setCategory(newValue) }}
                  renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Category"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  value={sector}
                  options={startup_sub_categories && startup_sub_categories?.length > 0 ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?._id })) : []}
                  autoHighlight
                  onChange={(e, newValue) => { formik.setValues({ ...formik.values, sector: newValue?.label }); setSector(newValue) }}
                  renderInput={(params) => <CustomInputField variant={"outlined"} placeholder={"Sector"} {...params} sx={{ marginBottom: 0 }} className="filter-autocomplete" />}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} gap={2} display={"flex"} flexWrap={"nowrap"} justifyContent={"center"}>
              <B300
                type="reset"
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", bgcolor: "#e9f5f8", ":hover": { bgcolor: "#e9f5f8" }, color: theme.palette.customColors.b300, minWidth: 120 }}
              >
                Reset
              </B300>
              <B300
                type="submit"
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", bgcolor: theme.palette.customColors.b300, ":hover": { bgcolor: theme.palette.customColors.b400 }, minWidth: 120 }}
              >
                Filter
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default FilterPopup;
