import React from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PerfectScrollbar from "react-perfect-scrollbar"
import BreadcrumbHeader from '../../../layout/BreadcrumbsHeader'
import { SECONDARY, WHITEBG } from '../../../ui-components/CustomButton'
import { useTheme } from '@emotion/react'
import ThemeBGWrapper from '../../../ui-components/ThemeBGWrapper'
// import { FetchRoundViewDetails, FetchStartupDetails, FetchViewStartupDetails } from '../../../api'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, CircularProgress, Grid, Tab, Tabs, Typography, useMediaQuery, IconButton, Tooltip } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { ReactComponent as RoundDetailsIcon } from "../../../assets/rounds_icons/round_details_icon.svg"
import { ReactComponent as RoundInvestorsIcon } from "../../../assets/rounds_icons/round_investor_icon.svg"
import { ReactComponent as RoundDocumentsIcon } from "../../../assets/rounds_icons/round_documents.svg"
import RoundInformation from './RoundInformation'
// import RoundDocuments from './RoundDocuments'

import { ReactComponent as InfoIcon } from '../../../assets/help-circle.svg';
import CustomTabs from '../../../ui-components/CustomTabs'
import { containerRef, handleCopyClick } from '../../../utils'
import { ContentCopyOutlined, FileCopy } from '@mui/icons-material'
import NeedHelpButton from 'ui-components/NeedHelpButton'
import RoundDocuments from './RoundDocuments'
import StartupInformation from './StartupInformation'
import { FetchRoundViewDetails, FetchViewStartupDetails } from 'api/startups'
import Cookies from 'js-cookie'
// import ViewProfile from './ViewProfile'
// import ViewFeedback from './ViewFeedback'
// import RoundInvestorsNew from './RoundInvestorsNew'

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        '& > .Mui-disabled.MuiTabs-scrollButtons': {
            display: 'none',
            transition: 'all 1s ease'
        }
    }
}));




const ViewDetails = () => {
    // const { id: round_id } = useParams();
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles();
    const Actions = () => {
        return (
            <>
                <NeedHelpButton />
            </>
        );
    };

    let tabValue = new URLSearchParams(window.location.search).get("page");
    // console.log("tabl",tabValue)

    const [value, setValue] = React.useState(tabValue || "startup_info");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let roundTabs

    const [copied, setCopied] = React.useState(false)
    // const startupDetails = { data: {
    //     "_id": "66fbc3b99ce0f2c56165bd23",
    //     "user_id": "66fbc305c05bcd8c5743ad7e",
    //     "cofounderArray": [
    //         {
    //             "cofounder_image": "siddharth-gupta-01.jpeg",
    //             "name": "Siddharth Gupta",
    //             "title": "Co-Founder and CTO",
    //             "bio": "Building products that solve real world problems for enterprises and end-users.",
    //             "linkedin_url": "startup.backrr.in/onboarding?page=11",
    //             "cofounder_image_path": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/siddharth-gupta-01.jpeg"
    //         }
    //     ],
    //     "documents_urls": [],
    //     "faqs": [],
    //     "first_name": "Kamal",
    //     "founder": [
    //         {
    //             "name": "Arnav Gupta",
    //             "bio": "Arnav , 23 is a young entrepreneur currently building We360.ai - a workforce analytics software helping organisations and individuals to manage the efficiency",
    //             "title": "Founder & CEO",
    //             "linkedin_url": "https://www.linkedin.com/in/4arnav/",
    //             "founder_image": "founder.jpeg",
    //             "founder_image_path": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/founder.jpeg"
    //         }
    //     ],
    //     "internal_tags_logs": [],
    //     "investor_comments": [],
    //     "is_profile_completed": true,
    //     "last_name": "Kumar",
    //     "mobile_number": "8899889988",
    //     "phone_code": "91",
    //     "show_in_app": false,
    //     "step": "12",
    //     "country_id": "63722c31dd7259a055365c0b",
    //     "city_id": "63722c7edd7259a055372a50",
    //     "state_id": "63722c4fdd7259a055366325",
    //     "stage_id": "66bafcc58240d6a91b9981a8",
    //     "startup_name": "We360.ai",
    //     "entity_name": "Zenstack Pvt. Ltd.",
    //     "formation_date": "2023-01-24T18:30:00.000Z",
    //     "logo_url": "logo.png",
    //     "category_id": "66f54ee7a89ff702f939e7c8",
    //     "sector_id": [
    //         "66f551f3a89ff702f939e813"
    //     ],
    //     "pitch_deck_url": "pitch-deck.pdf",
    //     "slug": "old-we360",
    //     "description": "<p>We360.ai is a cloud based productivity monitoring software that helps you gain insights into your employees work environment. It gives you the ability to turn data from all corners of your workplace into meaningful information and actionable insights to help you improve the way people work.</p><p><br></p><p>With more than 20,000 users worldwide, We360.ai's award-winning solution can be configured in minutes to provide immediate visibility into how users are engaging in your organisation. We360.ai is the most powerful means to observe how digital enablement efforts are impacting your organisation. We360.ai is a perfect tool for HRs and business leaders looking to increase employee productivity and We360.ai is easy to use, comes with a free trial and gets configured within minutes. Whether it is 24x7 remote support or dedicated account manager we have got you covered at all times.</p>",
    //     "problem_solving": "Improve your employee engagement and make better decisions for your organization with factual workplace analytics!",
    //     "linkedin_url": "https://www.linkedin.com/company/we360-ai/",
    //     "website_url": "https://we360.ai",
    //     "referral_type": "Google",
    //     "elevator_pitch": "elevator.mp4",
    //     "city": "Jaipur",
    //     "state": "Rajasthan",
    //     "country": "India",
    //     "stage_info": {
    //         "name": "Seed",
    //         "subtitle": "Product development",
    //         "code": "seed",
    //         "image": "seed.webp",
    //         "_id": "66bafcc58240d6a91b9981a8"
    //     },
    //     "sector_info": [
    //         {
    //             "sub_category": "HRTech",
    //             "description": "Human resource technologies (e.g., recruitment platforms, employee management tools)",
    //             "_id": "66f551f3a89ff702f939e813"
    //         }
    //     ],
    //     "category_info": {
    //         "name": "Technology",
    //         "code": "Technology",
    //         "_id": "66f54ee7a89ff702f939e7c8"
    //     },
    //     "startup_logo": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/logo.png",
    //     "elevator_pitch_path": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/elevator.mp4",
    //     "formatted_formation_date": "25-Jan-2023",
    //     "pitch_deck_path": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/pitch-deck.pdf",
    //     "pitch_deck_thumbnail_path": "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/pitch-deck-thumbnail.png",
    //     "backrr_base_url": "https://backrr.in/a/s/old-we360",
    //     "document_base_url": "https://backrr.in/a/s/old-we360/d/pitchdeck"
    // } };
    // const isLoadingStartupDetails = false;
    // const refetchStartupDetails = () => {

    // }

    const roundDetailsInformation = {
        "_id": "66fbc4bbc05bcd8c5743adc7",
        "user_id": "66fbc305c05bcd8c5743ad7e",
        "startup_id": [
            "66fbc3b99ce0f2c56165bd23"
        ],
        "round_name": "Seed Round",
        "round_type": "66c43512a7f108b3f7c08efb",
        "key_metrics": [
            {
                "heading": "User",
                "properties": [
                    {
                        "key": "DAU",
                        "value": "20k"
                    },
                    {
                        "key": "MAU",
                        "value": "30k"
                    }
                ]
            }
        ],
        "faqs": [],
        "raised_round": false,
        "documents": [
            {
                "title": "Monthly MIS Report",
                "uploadedBy": "66fbc305c05bcd8c5743ad7e",
                "filename": "monthly_mis_report_66fbc4bbc05bcd8c5743adc7.pdf",
                "file_type": ".pdf",
                "createdAt": "2024-10-11T06:37:50.662Z",
                "updatedAt": "2024-10-11T06:37:50.662Z",
                "_id": "6708c7bedddd0a885deb842f"
            },
            {
                "title": "Budget MIS Report",
                "uploadedBy": "66fbc305c05bcd8c5743ad7e",
                "filename": "budget_mis_report_66fbc4bbc05bcd8c5743adc7.png",
                "file_type": ".png",
                "createdAt": "2024-10-11T06:40:21.646Z",
                "updatedAt": "2024-10-11T06:40:21.646Z",
                "_id": "6708c855dddd0a885deb8474"
            }
        ],
        "investors": [],
        "fund_allocation_percentages": [
            {
                "name": "Product & Technology",
                "percentage": 50,
                "_id": "66ff5816479464b6696f83e7"
            },
            {
                "name": "Sales & Marketing",
                "percentage": 30,
                "_id": "6700ee38f35cb6ea26766477"
            },
            {
                "name": "Customer Success & Operations",
                "percentage": 20,
                "_id": "6700ee38f35cb6ea26766478"
            }
        ],
        "security_type": "66bc630da0461f1ea29d522c",
        "committed_funding_amount": "100000",
        "currency_id": "66951552a6ed3a6eeb997bd3",
        "minimum_cheque_size": "100000",
        "round_highlights": "<ul><li>CrozDesk awarded We360.ai with Happiest Users Award and Quality Choice award in March 2021&nbsp;</li><li>We360.ai got Startup India recognition by the Government of India in February 2021&nbsp;</li><li>We360.ai is the highest-rated rated software on every software review platform and has been constantly outperforming the industry leaders over the months</li><li>Awarded as the Momentum Leader of summer 2022 on G2</li></ul>",
        "round_valuation": "200000000",
        "total_round_size": "50000000",
        "round_valuation_cap": "200000000",
        "exit_highlights": "<p>We shall provide exit to our investors during Series A round or IPO</p>",
        "monthly_net_burn": "0",
        "round_closing_date": "2024-11-07T18:30:00.000Z",
        "round_start_date": "2024-08-31T18:30:00.000Z",
        "round_status": "66bc639ba0461f1ea29d5235",
        "runway_left": "12+",
        "fund_utilization_highlights": "<p><strong>Product &amp; Technology</strong></p><ul><li>Hiring Engineers</li><li>Launching the Freemium model</li><li>Compliance Certification (SOC2 Process)</li><li>Launch of V2 Product&nbsp;</li></ul><p><br></p><p><strong>Sales &amp; Marketing</strong></p><ul><li>US GTM</li><li>Double down on partnerships model</li><li>Indian GTM evolution&nbsp;&nbsp;</li></ul><p><br></p><p><strong>Customer Success &amp; Operations&nbsp;</strong></p><ul><li>US operations&nbsp;</li></ul>",
        "received_termsheet": false,
        // "traction_highlights": "<p>We are currently at 150 paid customers and increasing at 10% MoM.</p>",
        "currency_info": "$",
        "security_type_info": {
            "name": "CCPS",
            "code": "CCPS",
            "_id": "66bc630da0461f1ea29d522c"
        },
        "round_type_info": {
            "name": "Seed",
            "code": "Seed",
            "image": "Automation.png",
            "_id": "66c43512a7f108b3f7c08efb"
        },
        "round_status_info": {
            "name": "Active",
            "code": "Active",
            "_id": "66bc639ba0461f1ea29d5235"
        },
        "round_valuation_final": "$200M",
        "round_valuation_cap_final": "$200M",
        "total_round_size_final": "$50M",
        "minimum_cheque_size_final": "$100K",
        "committed_funding_amount_final": "$100K",
        "monthly_net_burn_final": "$0",
        "final_round_closing_date": "08-Nov-2024",
        "final_round_start_date": "01-Sep-2024"
    }
    const [searchParams, setSearchParams] = useSearchParams();
    let startup_id = searchParams.get("startup_id");
    let cookieStartupId = Cookies.get("startup_id");
    // console.log(cookieStartupId, "frgt")

    React.useEffect(() => {
        if (cookieStartupId) {
            searchParams.delete("startup_id");
            setSearchParams(searchParams); // Update the URL without startup_id
        } else if (startup_id?.trim()?.length > 0 && startup_id !== null) {
            Cookies.set("startup_id", startup_id);
            searchParams.delete("startup_id");
            setSearchParams(searchParams); // Update the URL without startup_id
        } else {
            navigate(-1); // Navigate back if no valid startup_id
        }
    }, [cookieStartupId, startup_id, setSearchParams, navigate, searchParams]);
    // const startup_id = Cookies.get("startup_id");
    const { data: startupDetails, isLoading: isLoadingStartupDetails, refetch: refetchStartupDetails } = FetchViewStartupDetails(cookieStartupId)
    // console.log(startupDetails, "startupDetails");

    roundTabs = startupDetails?.data?.round_id ? [
        {
            label: "Information",
            // icon: RoundDetailsIcon,
            value: "startup_info",
        },
        // {
        //     label: "Investors",
        //     icon: RoundInvestorsIcon,
        //     value: "investors",
        // },
        {
            label: "Round Details",
            // icon: RoundInvestorsIcon,
            value: "round_details",
        },
        {
            label: "Documents",
            // icon: RoundDocumentsIcon,
            value: "round_documents"
        }
        // ,
        // {
        //     label: "Investors Documents",
        //     icon: RoundDetailsIcon,
        //     value: "investors_documents"
        // }
    ] :
        [
            {
                label: "Information",
                // icon: RoundDetailsIcon,
                value: "startup_info",
            }
        ]


    return (
        <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
            <BreadcrumbHeader title={() => <>{isLoadingStartupDetails ? "Loading..." : isSmallScreen ? "View Details" : startupDetails?.data?.startup_name ? startupDetails?.data?.startup_name : "View Details"} </>} Actions={Actions} sx={{ paddingBottom: 0 }} />
            {isLoadingStartupDetails ?
                <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingTop: "1rem", bgColor: theme.palette.customColors.yellow_bg }}>
                    {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
                    <Box className='skeleton' height={80}>

                    </Box>
                    <Box className='skeleton' height={"calc(100% - 80px)"} marginTop={2}>

                    </Box>
                </Grid>
                : <>


                    <Grid xs={12} container bgColor={theme.palette.customColors.yellow_bg} height={"100%"}>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box px={isSmallScreen ? "1rem" : "2rem"} bgColor={theme.palette.customColors.yellow_bg}>
                                    <CustomTabs data={roundTabs} value={value} isSmallScreen={isSmallScreen} className={classes} handleChange={handleChange} key={"Tabs Tabs"} sx={{ paddingTop: '1rem' }} bgColor={theme.palette.customColors.yellow_bg} />
                                </Box>
                                <TabPanel value="startup_info" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <StartupInformation data={startupDetails?.data} round_id={"wefrtg"} />
                                    </PerfectScrollbar>
                                </TabPanel>
                                {startupDetails?.data?.round_id && <TabPanel value="round_details" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <RoundInformation data={{}} round_id={startupDetails?.data?.round_id} />
                                    </PerfectScrollbar>
                                </TabPanel>}
                                {startupDetails?.data?.round_id && <TabPanel value="round_documents" sx={{ paddingInline: 0, height: '100%' }}>
                                    <PerfectScrollbar containerRef={containerRef} style={{ height: isLargeScreen ? "calc(100vh - 190px)" : "calc(100vh - 185px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                                        <RoundDocuments round_id={startupDetails?.data?.round_id} />
                                    </PerfectScrollbar>
                                </TabPanel>}
                            </TabContext>
                        </Box>
                    </Grid>
                </>
            }
        </ThemeBGWrapper>
    )
}

export default ViewDetails