import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";
import { useDispatch as dispatch } from "react-redux";
import { SET_STARTUP_DETAILS } from "../store/actions";
import moment from "moment";

export const AddStartup = ({ setProgress }) => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/startups`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                onUploadProgress: (data) => {
                    setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchStartupDetails = (slug = "mymandi") => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchStartupDetails-FetchStartupDetails-FetchStartupDetails-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}investors/startup-details-by-slug/${slug}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data;
            // console.log(data,"new Data")
            return { data: savedData?.data[0], status: savedData?.statusCode }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchProfileDetails = () => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchProfileDetails-FetchProfileDetails-FetchProfileDetails-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/user-info`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data[0];
            const personal_information = savedData?.personal_information
            const profile_picture = savedData?.profile_picture
            const preferences = savedData?.preferences
            const investment_size_preference = savedData?.investment_size_preference
            const previous_investment_experience = savedData?.previous_investment_experience
            const startups = savedData?.startups
            const whatsapp_notifications = savedData?.whatsapp_notifications
            const linkedIn_profile = savedData?.linkedIn_profile
            let date = ""
            let month = ""
            let year = ""
            if (personal_information?.dob) {
                let dateOfBirth = moment(personal_information?.dob).format("MM-DD-YYYY")
                month = dateOfBirth.split("-")[0]
                date = dateOfBirth.split("-")[1]
                year = dateOfBirth.split("-")[2]
            }
            let starupArray = []
            if (startups) {
                let modifiedStartups = []
                if (startups?.length > 0) {
                    startups.map((item, index) => {
                        modifiedStartups.push({
                            id: index + 1,
                            startup_name: item.startup_name,
                            startup_url: item.startup_url,
                            edit: false
                        })
                    })
                } else {
                    modifiedStartups.push({
                        id: 0,
                        startup_name: null,
                        startup_url: null,
                        edit: true
                    })
                }
                starupArray = [...starupArray, ...modifiedStartups]
            } else {
                starupArray.push(
                    {
                        id: 0,
                        startup_name: null,
                        startup_url: null,
                        edit: true
                    }
                )
            }
            const savedValues = {
                // step 1
                first_name: personal_information?.first_name ? String(personal_information?.first_name) : '',
                last_name: personal_information?.last_name ? String(personal_information?.last_name) : '',
                title: personal_information?.title ? String(personal_information?.title) : '',
                dd: date,
                mm: month,
                yyyy: year,
                bio: personal_information?.bio || '',
                date_of_birth: personal_information?.dob ? moment(personal_information?.dob).format("DD-MMM-YYYY") : null,
                custom_image_url: profile_picture?.profile_path?.trim()?.length > 0 ? profile_picture?.profile_path : null,
                avatar_selected: profile_picture?.custom_image_url?.trim()?.length > 0 ? null : profile_picture?.avatar_selected ? profile_picture?.avatar_selected : null,
                profile_path: profile_picture?.profile_path?.trim()?.length > 0 ? profile_picture?.profile_path : null,
                country_id: personal_information?.country_id ? personal_information?.country_id : null,
                state_id: personal_information?.state_id ? personal_information?.state_id : null,
                city_id: personal_information?.city_id ? personal_information?.city_id : null,
                country_name: savedData?.country ? savedData?.country : null,
                state_name: savedData?.state ? savedData?.state : null,
                city_name: savedData?.city ? savedData?.city : null,
                category_id: preferences?.category_id ? preferences?.category_id : null,
                sector_id: preferences?.preferred_sectors ? preferences?.preferred_sectors : [],
                sector_type: preferences?.sector_type ? preferences?.sector_type : "Multiple",
                investment_size: investment_size_preference?.investment_size ? investment_size_preference?.investment_size : null,
                min: investment_size_preference?.min_amount ? investment_size_preference?.min_amount : null,
                max: investment_size_preference?.max_amount ? investment_size_preference?.max_amount : null,
                investment_amount_type: investment_size_preference?.currency ? investment_size_preference?.currency : null,
                stage_id: preferences?.startup_stages ? preferences?.startup_stages : [],
                business_model_id: preferences?.business_model_id ? preferences?.business_model_id : [],
                business_model_type: preferences?.business_model_type ? preferences?.business_model_type : "Multiple",
                investment_count: previous_investment_experience?.investment_count ? previous_investment_experience?.investment_count : null,
                startups: starupArray,
                enabled: whatsapp_notifications?.enabled ? whatsapp_notifications?.enabled : false,
                whatsapp_number: whatsapp_notifications?.whatsapp_number ? whatsapp_notifications?.whatsapp_number : null,
                otp_verified: whatsapp_notifications?.otp_verified ? whatsapp_notifications?.otp_verified : false,
                profile_url: linkedIn_profile?.profile_url ? linkedIn_profile?.profile_url : null,
                public_url: savedData?.slug ? savedData?.slug : (String(personal_information?.first_name + personal_information?.last_name)?.toLowerCase()),
                backrr_base_url: savedData?.backrr_base_url || '',
                linkedin_public_profile: savedData?.linkedin_public_profile ? savedData?.linkedin_public_profile : false,
                sector_info: savedData?.sector_info ? savedData?.sector_info : [],
                stage_info: savedData?.stage_info ? savedData?.stage_info : [],
                business_model_info: savedData?.business_model_info ? savedData?.business_model_info : [],
                category_info: savedData?.category_info ? savedData?.category_info : null,
                step: savedData?.step || 1,
                _id: savedData?._id
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        cacheTime: 0,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const SavePersonalInfo = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveProfileImage = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/user_profile_image`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveStartupInfo = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/save_startup_info`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const SendWhatsappOTP = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/whatsapp-notification`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const VerifyWhatsAppOTP = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/investors/verify-whatsapp-otp`,
            headers: {
                Authorization: `Bearer ${Cookies.get('jwt')}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            data: payload
        });
    }, {
        retry: 0,
        onError: (err) => {
            // Handle the error
            console.error(err);
        },
    });
};
export const VerifyPublicURL = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/investors/slug`,
            headers: {
                Authorization: `Bearer ${Cookies.get('jwt')}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            data: payload
        });
    }, {
        retry: 0,
        onError: (err) => {
            // Handle the error
            console.error(err);
        },
    });
};

// bg-Component_11 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -10px -10px;
// }

// x
// .bg-Component_11_1 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -94px -10px;
// }

// x
// .bg-Component_11_2 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -10px -94px;
// }

// x
// .bg-Component_11_3 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -94px -94px;
// }

// x
// .bg-Component_11_4 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -178px -10px;
// }

// x
// .bg-Component_11_5 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -178px -94px;
// }

// x
// .bg-Component_11_6 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -10px -178px;
// }

// x
// .bg-Component_11_7 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -94px -178px;
// }

// x
// .bg-Component_11_8 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -178px -178px;
// }

// x
// .bg-Component_11_9 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -262px -10px;
// }

// x
// .bg-Component_48 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -262px -94px;
// }

// x
// .bg-Component_48_1 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -262px -178px;
// }

// x
// .bg-Component_48_2 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -10px -262px;
// }

// x
// .bg-Component_48_3 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -94px -262px;
// }

// x
// .bg-Component_48_4 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -178px -262px;
// }

// x
// .bg-Component_48_5 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -262px -262px;
// }

// x
// .bg-Component_48_6 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -346px -10px;
// }

// x
// .bg-Component_48_7 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -346px -94px;
// }

// x
// .bg-Component_48_8 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -346px -178px;
// }

// x
// .bg-Component_48_9 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -346px -262px;
// }

// x
// .bg-Component_48_10 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -10px -346px;
// }

// x
// .bg-Component_48_11 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -94px -346px;
// }

// x
// .bg-Component_48_12 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -178px -346px;
// }

// x
// .bg-Component_48_13 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -262px -346px;
// }

// x
// .bg-Component_48_14 {
//     width: 64px; height: 64px;
//     background: url('css_sprites.png') -346px -346px;
// }