import React from 'react'
// import video from "../assets/sample-5s.mp4"
// import Image from '../assets/pitchdeck.jpg'
import { useTheme } from '@emotion/react';

const ThumbnailGenerator = ({ type, height = 300, url, thumbnail = "", action }) => {
    const theme = useTheme();
    return (
        <div style={{ width: '99%', minHeight: 50, display: "flex", alignItems: "center", textAlign: 'center', justifyContent: 'center', overflow: 'hidden', background: '#140000', borderRadius: '15px', border: `1px solid ${theme.palette.customColors.g50}` }}>
            {type === "video" ?
                <video controls width={"100%"} height={height}>
                    <source src={url}></source>
                </video>
                : <img onClick={action ? action : () => { }} src={thumbnail} width={"100%"} height={height} style={{ overflow: 'hidden', objectFit: 'cover', objectPosition: 'top center', cursor: action ? 'pointer' : 'default' }} />}
        </div>
    )
}

export default ThumbnailGenerator