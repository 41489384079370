

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    role: null
}

const roleDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ROLE_DETAILS:
            return {
                ...state,
                role: action.role
            }
        default:
            return state;
    }
}

export default roleDetailsReducer
