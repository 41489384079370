import { Box, Grid } from '@mui/material'
import React from 'react'
// import AuthWrapper1 from 'ui-components/AuthWrapper1'
import CustomBackgroundComponent from 'ui-components/CustomBackgroundComponent'
import AuthLogin from 'views/pages/AuthLogin'
import { useTheme } from '@emotion/react'

const OnboardingLayout = ({ children }) => {
    const theme = useTheme()
    return (
        <Grid xs={12} container sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", minHeight: "100vh", backgroundColor: theme.palette.customColors.yellow_bg }}>
            <Box className="container" sx={{ padding: { xs: "0 !important", md: "1rem !important", lg: "1rem !important" }, maxHeight: { xs: "100%", md: 600, lg: 730 } }}>
                <div class="form-section">
                    {children}
                </div>
                <div className='illustration-section' />
            </Box>
        </Grid>
    )
}

export default OnboardingLayout