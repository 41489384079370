import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import React from 'react'

const CustomHeaderIcon = ({customIcon}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    return (
      <img style={{ width: "4.5rem", height: "4.5rem", marginInline:  isSmallScreen || isMediumScreen  ? "auto" : 0}} src={customIcon} />
    )
}

export default CustomHeaderIcon