import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import toast from "react-hot-toast";

const CustomToast = ({ message = "", description, style = {}, closeIcon, closeIconPosition }) => {
    const position = () => {
        switch (closeIconPosition) {
            // case "top":
            //     return "2px"

            // case "center":
            //     return "24%"

            // case "bottom":
            //     return "100%"

            default:
                return "2px"
        }
    }
    return (
        <div className="get" style={{ display: 'flex', alignItems: 'center', }}>
            <div style={{}}>
                <p style={{ margin: 0, ...style, paddingLeft: 0 }}>{message}</p>
                {description && <p style={{ margin: 0 }}>{description}</p>}
            </div>
            {closeIcon && <IconButton sx={{ position: "absolute", top: position, right: '2px', padding: '1px' }} onClick={() => toast.dismiss()}>
                <Close sx={{width: 20, height: 20}} />
            </IconButton>}
        </div>
    )
};

export default CustomToast