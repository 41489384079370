import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import React from 'react'
import { useTheme } from '@emotion/react';
import { B300 } from 'ui-components/CustomButton';
import { RateReview, ThumbUp } from '@mui/icons-material';
import { Document, Page } from 'react-pdf';
import NoData from 'ui-components/NoData';

const PDFView = ({ open, handleClose, handleShowInterestPopupOpen, handleFeedbackPopupOpen, pdfUrl, currentRow, isEnabledInterest, isEnabledFeedback, title}) => {
    const theme = useTheme();
    const [numPages, setNumPages] = React.useState(1);

    return (
        <Dialog fullScreen={true} open={open} onClose={() => handleClose()} sx={{}}>
            <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.customColors.gray300}` }}>
                <Grid sx={{ position: 'sticky', padding: "0", backgroundColor: '#FFF', zIndex: 1, marginBottom: '0.5rem' }} display={'flex'} alignItems={'center'}>
                    <Grid style={{ flex: 1 }}>
                        <Typography fontWeight={700} fontSize={"1.3rem"} textAlign={'center'}>{title ? `${title} of ${currentRow?.startup_name}` : `Pitch Deck of ${currentRow?.startup_name}`}</Typography>
                    </Grid>
                    <IconButton disableRipple={true} sx={{ padding: 0 }}>
                        <CloseIcon width={40} height={40} style={{ marginRight: "-15px" }} onClick={() => { handleClose() }} />
                    </IconButton>
                </Grid>
                {!title && (
                <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
                    <B300 onClick={() => handleShowInterestPopupOpen({ row: { original: currentRow } })} startIcon={<ThumbUp sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                        Show Interest
                    </B300>
                    <B300 onClick={() => handleFeedbackPopupOpen({ row: { original: currentRow } })} startIcon={<RateReview sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                        Submit Feedback
                    </B300>
                </Grid>
                )}
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <div style={{ overflow: 'scroll', height: '84vh', border: `1px solid ${theme.palette.customColors.gray300}` }}>
                    <Document error={() => { return <NoData /> }} loading={<Box className="skeleton" height={"calc(100vh - 160px)"} width={"100%"}></Box>} file={pdfUrl} onLoadSuccess={({ numPages }) => { setNumPages(numPages) }}>
                        <div id='pdf_container2' style={{ backgroundColor: theme.palette.customColors.gray300, width: '100%' }}>
                            {Array.apply(null, Array(numPages))
                                .map((x, i) => i + 1)
                                .map((page, index) => (
                                    <div id='page_view' style={{ marginBottom: index != numPages - 1 ? '0.7rem' : 0 }}>
                                        <Page
                                            key={page}
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                            scale={1}
                                            width={window.innerWidth}
                                            loading={<Box className="skeleton" height={"calc(100vh - 160px)"} width={"100%"}></Box>}
                                        // width={pageWidth > 0 ? pageWidth : document.getElementById('pdf_container2')?.parentNode.clientWidth}
                                        />
                                    </div>
                                ))}
                        </div>
                    </Document>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default PDFView