import pdfIcon from "../assets/doc_icons/pdf.svg"
import imageIcon from "../assets/doc_icons/image.svg"
import docsIcon from "../assets/doc_icons/docx2.svg"
import excelIcon from "../assets/doc_icons/excel.svg"
import videoIcon from "../assets/doc_icons/video.svg"
import documentIcon from "../assets/doc_icons/document.svg"
import imageIcon2 from "../assets/doc_icons/image2.svg"
import filmIcon from "../assets/doc_icons/film.svg"

const handleDateValues = (date, placeholder = "No Date") => {
  return date && String(date)?.trim()?.length > 0 ? date : placeholder
}

const getFileIcon = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return pdfIcon; // Replace with actual path to your PDF icon image
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return imageIcon; // Replace with actual path to your image icon
    case 'doc':
    case 'docx':
      return docsIcon; // Replace with actual path to your DOC/DOCX icon
    case 'xls':
    case 'xlsx':
    case 'csv':
      return excelIcon; // Replace with actual path to your Excel icon
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
    case 'wmv':
      return videoIcon; // Replace with actual path to your video icon
    default:
      return docsIcon; // Replace with actual path to a default icon for unsupported types
  }
};

//   // Example usage
//   const fileName = 'example.pdf';
//   const iconPath = getFileIcon(fileName);
//   console.log(iconPath); // Outputs the path to the corresponding icon based on the file extension

const getFileCategory = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
    case 'doc':
    case 'docx':
    case 'xls':
    case 'xlsx':
    case 'csv':
    case 'ppt':
    case 'pptx':
    case 'txt':
      return documentIcon; // Category for document files

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      return imageIcon2; // Category for image files

    case 'mp4':
    case 'mov':
    case 'avi':
    case 'mkv':
    case 'wmv':
    case 'flv':
      return filmIcon; // Category for video files

    default:
      return documentIcon; // Default case for unsupported or unknown file types
  }
};

//   // Example usage
//   const fileName = 'example.mp4';
//   const fileCategory = getFileCategory(fileName);
//   console.log(fileCategory); // Outputs 'video'

const currencyInputProps = {
  inputMode: "numeric", // Use numeric keyboard on mobile devices
  pattern: "[0-9]*", // Only allow digits 0-9
  // title: "Please enter a number between 0 and 9",
  style: {
    // textAlign: 'center', // Center the text in the input
  },
  min: 0,
  onKeyDown: (event) => {
    // Prevent the minus sign (-) from being entered
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
    if (event.key === "-") {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    // Get the input value
    const inputValue = event.key;

    // Allow only numeric characters (0-9) and certain control keys
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(inputValue)) {
      // If not, prevent the key from being entered
      event.preventDefault();
    }
  },
  onInput: (event) => {
    // Get the input value
    const inputValue = event.target.value;

    // Remove non-numeric characters using a regular expression
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Update the input field value with the filtered numeric value
    event.target.value = numericValue;
  },
  onKeyDown: (event) => {
    // Prevent the input of minus sign (key code 189)
    if (event.keyCode === 189 || event.key === "-") {
      event.preventDefault();
    }
  },
  disableUnderline: true,
};

const containerRef = (ref) => {
  if (ref) {
    // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
    ref._getBoundingClientRect = ref.getBoundingClientRect;

    ref.getBoundingClientRect = () => {
      const original = ref._getBoundingClientRect();

      return {
        ...original,
        width: Math.floor(original.width),
        height: Math.floor(original.height),
      };
    };
  }
}

const isRequired = (requiredObject, field) => {
  return requiredObject[field]?.required
}

const popularCountrySort = ["India", "United States", "United Arab Emirates", "Singapore", "United Kingdom", "Canada"]

// const linkedInTestURL = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/
// const linkedInTestURL = /linkedin\.com\/[a-zA-Z0-9_-]+\/?$/
const linkedInTestURL = /linkedin\.com\/.*$/


function stringAvatar(current_name) {
  let name = (current_name)?.length > 0 ? String(current_name)?.replace(" ", "") : ""
  if (name && name?.includes(" ")) {
    return {
      sx: {
        bgcolor: "#F9F5FF",
        color: "#3538CD",
        width: "40px",
        height: "40px",
        fontSize: '1rem',
        textTransform: 'capitalize'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  } else if (name && !(name?.includes(" "))) {
    return {
      sx: {
        bgcolor: "#F9F5FF",
        color: "#3538CD",
        width: "40px",
        height: "40px",
        fontSize: '1rem',
        textTransform: 'capitalize'
      },
      children: name[0],
    };
  }
  else {
    return {
      sx: {
        bgcolor: "#F9F5FF",
        color: "#3538CD",
        width: "40px",
        height: "40px",
        fontSize: '1rem',
        textTransform: 'capitalize'
      },
      children: '',
    }
  }
}

const capitalizedSentence = (sentence) => sentence && sentence?.length > 0 ? sentence?.split(' ')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1)).join(' ') : '';

const handleCopyClick = (copyLink, copied = false, setCopied) => {
  // Perform the copy action (e.g., copying text to clipboard)
  navigator.clipboard.writeText(copyLink);

  // Change the state to show the clicked (copied) icon
  setCopied(true);

  // Optionally reset the icon back after a delay
  setTimeout(() => setCopied(false), 2000);
};

const interestOptions = (value) => {
  switch (value) {
    case '$': return [
      { label: '+ $1,000', value: "1000" },
      { label: '+ $2,000', value: "2000" },
      { label: '+ $3,000', value: "3000" },
      { label: '+ $5,000', value: "5000" },
      { label: '+ $10,000', value: "10000" },
    ];
    case '₹': return [
      { label: '+ ₹1,00,000', value: "100000" },
      { label: '+ ₹2,00,000', value: "200000" },
      { label: '+ ₹3,00,000', value: "300000" },
      { label: '+ ₹5,00,000', value: "500000" },
      { label: '+ ₹10,00,000', value: "1000000" },
    ];
    default: return [
      { label: '+ ₹1,00,000', value: "100000" },
      { label: '+ ₹2,00,000', value: "200000" },
      { label: '+ ₹3,00,000', value: "300000" },
      { label: '+ ₹5,00,000', value: "500000" },
      { label: '+ ₹10,00,000', value: "1000000" },
    ];
  }
}

const ones = [
  '',
  'One ',
  'Two ',
  'Three ',
  'Four ',
  'Five ',
  'Six ',
  'Seven ',
  'Eight ',
  'Nine ',
  'Ten ',
  'Eleven ',
  'Twelve ',
  'Thirteen ',
  'Fourteen ',
  'Fifteen ',
  'Sixteen ',
  'Seventeen ',
  'Eighteen ',
  'Nineteen '
];
const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

const largeUnits = ['Billion', 'Million', 'Thousand', '']; // Adjust for international units

export const convertToIndianCurrencyWords = (number) => {
  // if (number != undefined) {
  if (typeof number === 'number') {
    number = number.toString();
  }
  if (number === undefined || typeof number !== 'string') {
    return 'Please provide a valid input';
  }
  number = number.replace(/,/g, '');
  const parts = number.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1];
  number = integerPart;
  // console.log(parts[1], "parts[1]")

  if ((number = number.toString()).length > 13) return '';
  const n = ('0000000000000' + number).substr(-13).match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

  if (!n) return '';


  let str = '';
  str += n[1] !== '00' ? (
    (ones[Number(n[1])] || tens[n[1][0]] + ' ' + ones[n[1][1]]) +
    (n[2] === "00" ? 'Thousand Crore ' : 'Thousand ')
  ) : '';


  str += n[2] !== '00' ? (
    (ones[Number(n[2])] || tens[n[2][0]] + ' ' + ones[n[2][1]]) +
    (n[3] === "00" ? 'Hundred Crore ' : 'Hundred ')
  ) : '';

  str += n[3] !== '00' ? (ones[Number(n[3])] || tens[n[3][0]] + ' ' + ones[n[3][1]]) + 'Crore ' : '';
  str += n[4] !== '00' ? (ones[Number(n[4])] || tens[n[4][0]] + ' ' + ones[n[4][1]]) + 'Lakh ' : '';
  str += n[5] !== '00' ? (ones[Number(n[5])] || tens[n[5][0]] + ' ' + ones[n[5][1]]) + 'Thousand ' : '';
  str += n[6] !== '0' ? (ones[Number(n[6])] || tens[n[6][0]] + ' ' + ones[n[6][1]]) + 'Hundred ' : '';
  str += n[7] !== '00' ? (str !== '' ? ' ' : '') + (ones[Number(n[7])] || tens[n[7][0]] + ' ' + ones[n[7][1]]) : '';

  return str.trim() + (decimalPart != "00" && decimalPart != undefined ? " Point " + convertToIndianCurrencyWords(decimalPart) : "") || null;
  // }
  // else{
  //     return ""
  // }
};

export const convertToDollarWords = (number) => {
  if (typeof number === 'number') {
    number = number.toString();
  }
  if (number === undefined || typeof number !== 'string') {
    return 'Please provide a valid input';
  }
  number = number.replace(/,/g, '');
  const parts = number.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1];
  number = integerPart;

  if (number.length > 12) return ''; // limit to billions

  const n = ('000000000000' + number).substr(-12).match(/^(\d{3})(\d{3})(\d{3})(\d{3})$/);
  if (!n) return '';

  let str = '';
  str += n[1] !== '000' ? convertThreeDigitToWords(n[1]) + 'Billion ' : '';
  str += n[2] !== '000' ? convertThreeDigitToWords(n[2]) + 'Million ' : '';
  str += n[3] !== '000' ? convertThreeDigitToWords(n[3]) + 'Thousand ' : '';
  str += n[4] !== '000' ? convertThreeDigitToWords(n[4]) : '';

  return str.trim() + (decimalPart != "00" && decimalPart !== undefined ? " Point " + convertDecimalToWords(decimalPart) : "") || null;
};

function convertThreeDigitToWords(number) {
  const hundreds = Math.floor(number / 100);
  const remainder = number % 100;
  let str = '';

  if (hundreds > 0) {
    str += ones[hundreds] + 'Hundred ';
  }
  if (remainder > 0) {
    str += remainder < 20 ? ones[remainder] : tens[Math.floor(remainder / 10)] + ones[remainder % 10];
  }
  return str;
}

function convertDecimalToWords(decimalPart) {
  return decimalPart.split('').map(digit => ones[parseInt(digit)]).join('').trim();
}

const amountInWords = (value, currency) => {
  switch (currency) {
    case '$': return convertToDollarWords(value);
    case '₹': return convertToIndianCurrencyWords(value);
    default: return convertToIndianCurrencyWords(value);
  }
}

const scrollToFirstVisibleValidation = () => {
  // Query all elements with the class "validation-message"
  const validationMessages = document.querySelectorAll('.validation-message');
  // console.log(validationMessages, "dddd")

  // Find the first visible validation message
  for (const message of validationMessages) {
    if (message && message.offsetParent !== null) {
      message.scrollIntoView({ behavior: 'smooth', block: 'center' });
      break;
    }
  }
};

const handleFormSubmit = async (e, formik) => {
  e.preventDefault();
  const isValid = await formik.validateForm(); // Validate form before submission

  if (Object.keys(isValid).length > 0) {
    // If validation errors exist, trigger scroll
    formik.setTouched(isValid); // Mark all fields as touched
    setTimeout(() => {
      scrollToFirstVisibleValidation();
    }, 10)
  } else {
    formik.handleSubmit(); // Submit if there are no errors
  }
}

const notEmpty = (value) => {
  return !(value === null || value === undefined || value === '' || (typeof value == "string" && value?.trim()?.length === 0) ||
    (Array.isArray(value) && value.length === 0) ||
    (!(value instanceof Date) && typeof value === 'object' && Object.keys(value).length === 0));
}

function convertCurrencyAmountRange(amount, currency, noofDecimal = 2) {
  let result = '';
  
  // Check the currency type
  if (currency === 'INR') {
    if (amount >= 10000000) {
      result = (amount / 10000000).toFixed(noofDecimal) + ' Cr'; // Crores (10 million)
    } else if (amount >= 100000) {
      result = (amount / 100000).toFixed(noofDecimal) + ' L'; // Lakhs (100 thousand)
    } else if (amount >= 1000) {
      result = (amount / 1000).toFixed(noofDecimal) + ' K'; // Thousands
    } else {
      result = amount.toString(); // No conversion for smaller amounts
    }
  } else if (currency === 'USD') {
    if (amount >= 1000000) {
      result = (amount / 1000000).toFixed(noofDecimal) + 'M'; // Millions
    } else if (amount >= 1000) {
      result = (amount / 1000).toFixed(noofDecimal) + 'K'; // Thousands
    } else {
      result = amount.toString(); // No conversion for smaller amounts
    }
  } else {
    result = 'Invalid currency type';
  }

  return result;
}

const getINRMarks = () => [
  // { value: 0, label: '< 5L', selectedValue: 0 },
  { value: 0, label: '₹5L', selectedValue: 500000 },
  { value: 1, label: '₹25L',selectedValue: 2500000 },
  { value: 2, label: '₹50L', selectedValue: 5000000 },
  { value: 3, label: '₹2CR', selectedValue: 20000000 },
  { value: 4, label: '₹5CR', selectedValue: 50000000 },
  { value: 5, label: '₹20CR', selectedValue: 200000000 },
  { value: 6, label: '₹50CR +', selectedValue: 500000000 },
];

const getUSDMarks = () => [
  { value: 0, label: '$10K' ,selectedValue: 10000 },
  { value: 1, label: '$50K' ,selectedValue: 50000 },
  { value: 2, label: '$100K', selectedValue: 100000 },
  { value: 3, label: '$250K', selectedValue: 250000 },
  { value: 4, label: '$500K', selectedValue: 500000 },
  { value: 5, label: '$1M', selectedValue: 1000000 },
  { value: 6, label: '$5M', selectedValue: 5000000 },
  { value: 7, label: '$10M', selectedValue: 10000000 },
  // { value: 7, label: '> 10M', selectedValue: 10000001 },
];

const getMarks = (currency) => {
  return currency === 'INR' ? getINRMarks(currency) : getUSDMarks(currency)
}

const technologyColor = "#FFF8DE"
const consumerBrandColor = "#FFE3E3"
const marketplaceColor = "#F2FFE9"
const servicesColor = "#F5EFFF"
const sectorAgnostic = "#F0EBE3"

const colorArray = [
  "#D4F6FF",
  "#F6EFBD",
  "#FFE3E3",
  "#FFECC8",
  "#EDE8DC",
  "#F5EFFF",
  "#FEF9D9",
  "#F6EACB",
  "#FAF4B7",
  "#FFE6E6",
  "#FFF8DE",
  "#FFE8C5",
  "#F5EEE6",
  "#F2FFE9",
  "#F8E8EE",
  "#F8EDE3",
  "#F0EBE3",
  "#FEF2F4",
]

const isTitleToShow = (title) => {
  return ["Dr.", "CA"].includes(title)
}

export { handleDateValues, getFileIcon, getFileCategory, containerRef, isRequired, popularCountrySort, linkedInTestURL, stringAvatar, capitalizedSentence, handleCopyClick, interestOptions, notEmpty, currencyInputProps, amountInWords, handleFormSubmit , convertCurrencyAmountRange, getINRMarks, getUSDMarks, getMarks, technologyColor, consumerBrandColor, marketplaceColor, servicesColor, sectorAgnostic, colorArray, isTitleToShow}