import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: "#e6eaed"
    },
    secondary: {
      main: "#e9f5f8"
    },
    success: {
      main: '#eaf6ef'
    },
    warning: {
      main: "#fff8e6"
    },
    error: {
      main: "#fbe9e9"
    },
    customColors: {
      white: "#FFFFFF",
      yellow_bg: "#FFFAF5",
      purple_dark: "#6c3bc2",
      b50: '#ebe8fb',
      b75: '#ada2ed',
      b100: '#8c7ce6',
      b200: '#5a43db',
      b300: '#381dd4',
      b400: '#271494',
      b500: '#221281',
      //indigo25: '#F5F8FF',
      indigo25: '#FFFAF5',
      indigo50: '#EEF4FF',
      indigo100: '#E0EAFF',
      indigo600: '#444CE7',
      indigo700: "#3538CD",
      r50: '#feeded',
      r75: '#fdb7b7',
      r100: '#fc9999',
      r200: '#fa6d6d',
      r300: '#f94f4f',
      r400: '#ae3737',
      r500: '#983030',
      y50: '#fffae6',
      y75: '#ffe996',
      y100: '#ffe06b',
      y200: '#ffd22b',
      y300: '#ffc900',
      y400: '#b38d00',
      y500: '#9c7b00',
      g50: '#e8e9ea',
      g75: '#a1a3a7',
      g100: '#7a7d83',
      g200: '#40454d',
      g300: '#191f29',
      g400: '#12161d',
      g500: '#0f1319',
      g700: '#344054',
      g900: '#101828',
      gray200: "#EAECF0",
      gray300: "#D0D5DD",
      whiteBG: '#ffffff',
      inputField: '#f6f6f6',
      disable: '#e7e7e7',
      information: '#e6f1f8',
      checkboxcolor:"#7c4dff",
    },
  },
  background: {
    paper: "#FFFFFF"
  },
  typography: {
    // fontFamily: '"DM Sans", sans-serif', // Default font
    title: {
      fontSize: "1.75rem",
      fontWeight: 700,
      lineHeight: "40px",
      fontFamily: '"DM Sans", sans-serif', // Specific font for title
    },
    titleNormal: {
      fontSize: "1.75rem",
      fontWeight: 400,
      lineHeight: "40px",
      // color: '#101828'
    },
    h1: {
      fontSize: "1.75rem",
      fontWeight: 700,
      lineHeight: "32px",
      fontFamily: '"Lato", sans-serif', // Specific font for h1
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: "28px",
      fontFamily: '"Lato", sans-serif', // Specific font for h2
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: "24px",
      fontFamily: '"Lato", sans-serif', // Specific font for h3
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"DM Sans", sans-serif', // Use "DM Sans" for body text
    },
    bodyTextBold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important' // Use "Lato" for bold body text
    },
    bodyTextMedium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important' // Use "Lato" for medium body text
    },
    bodyTextRegular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important' // Use "Lato" for regular body text
    },
    label1Bold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"DM Sans", sans-serif', // Use "DM Sans" for label bold
    },
    label1Regular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important', // Use "Lato" for regular labels
    },
    label2Bold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    label2Medium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    label2Regular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    label3Bold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    label3Medium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    label4Regular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    label4Bold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    label4Medium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    label4Regular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    caption1Bold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    caption1Medium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    caption1Regular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    caption2Bold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    caption2Medium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    caption2Regular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    action1Bold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    action1Medium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    action1Regular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    action2Bold: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    action2Medium: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    action2Regular: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: '"Lato", sans-serif !important'
    },
    p: {
      fontFamily: '"Lato", sans-serif !important'
    },
    span: {
      fontFamily: '"Lato", sans-serif !important'
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
          '& .MuiFilledInput-root': {
            backgroundColor: '#ffffff',
            border: '1px solid #A1A3A7',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#ffffff',
            },
            '&.Mui-focused': {
              backgroundColor: '#ffffff',
              borderColor: '#A1A3A7',
            },
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#7A7D83',
            opacity: 1,
          },
          '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
            borderBottom: 'none',
          },
        },
      },
    },
  },
});


export default theme;
