import { Box, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { BLUEBORDER, WHITEBG } from '../../../ui-components/CustomButton';
import { ReactComponent as EditIcon } from "../../../assets/rounds_icons/edit_icon.svg";
import PieChart from '../../../ui-components/PieChart'
import { useTheme } from '@emotion/react';
import { handleDateValues, notEmpty } from '../../../utils';
import NoData from 'ui-components/NoData';
import { FetchRoundViewDetails } from 'api/startups';
import CardContentList from 'ui-components/CardContentList';

const CardHeader = ({ title, action = () => { }, actionText }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "" }) => {
    const [open, setOpen] = React.useState(false);

    let content = open ? htmlContent : htmlContent?.substring(0, 300);

    return (
        <>
            {content && content?.length > 0 ?
                <>
                    <div dangerouslySetInnerHTML={{ __html: content }} className='rich-text' style={{ color: color, textAlign: 'justify' }} />
                    {htmlContent?.length > 300 &&
                        <BLUEBORDER
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            sx={{
                                backgroundColor: 'transparent',
                                border: "0",
                                padding: "0"
                            }}
                            onClick={() => setOpen(!open)}
                        > <div>{!open ? 'See more' : 'See less'}</div>
                        </BLUEBORDER>
                    }
                </>
                : "-"
            }
        </>
    );
};

const RoundInformation = ({ data = {}, round_id }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { data: roundDetails, isLoading: isLoadingRoundDetails, refetch: refetchRoundDetails } = FetchRoundViewDetails(round_id);
    // console.log(roundDetails)
    const filteredRoundDetails = roundDetails ? roundDetails : {};

    React.useEffect(() => {
        roundDetails?.startup_id && delete filteredRoundDetails["startup_id"];
        roundDetails?.user_id && delete filteredRoundDetails["user_id"];
        roundDetails?._id && delete filteredRoundDetails["_id"];
    }, [])
    // console.log(Object.keys(filteredRoundDetails)?.length > 0,"roundDetails")
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const nameTypeDetails = [
        { label: "Round Name", value: roundDetails?.round_name },
        { label: "Round Type", value: roundDetails?.round_type_info?.name },
        { label: "Type of Security", value: roundDetails?.security_type_info?.name },
    ]?.filter(item => notEmpty(item?.value));
    const roundSizeValuationDetails = [
        { label: "Total Round Size", value: roundDetails?.total_round_size_final },
        { label: "Round Valuation Floor", value: roundDetails?.round_valuation_final },
        { label: "Round Valuation Cap", value: roundDetails?.round_valuation_cap_final },
        { label: "Minimum Cheque Size", value: roundDetails?.minimum_cheque_size_final },
        { label: "Have you already received any Term Sheet?", value: notEmpty(roundDetails?.received_termsheet) ? roundDetails?.received_termsheet ? "Yes" : 'No' : null },
        { label: "What funding amount has been committed so far?", value: roundDetails?.committed_funding_amount_final },
    ]?.filter(item => notEmpty(item?.value));
    const exitStrategyDetails = [
        { label: "Runway in Months", value: roundDetails?.runway_left > "1" ? roundDetails?.runway_left == "select_runway_left" ? null : roundDetails?.runway_left + " Months" : roundDetails?.runway_left == "1" ? roundDetails?.runway_left + " Month" : null },
        { label: "Monthly Net Burn", value: roundDetails?.monthly_net_burn_final ? roundDetails?.monthly_net_burn_final : null }
    ]?.filter(item => notEmpty(item?.value));

    const otherDetails = [
        { label: "Round Start Date", value: handleDateValues(roundDetails?.final_round_start_date, null) },
        { label: "Round Closing Date", value: handleDateValues(roundDetails?.final_round_closing_date, null) },
        { label: "Round Status", value: roundDetails?.round_status_info?.name ? roundDetails?.round_status_info?.name : null }
    ]?.filter(item => notEmpty(item?.value));
    // console.log(filteredRoundDetails, "filteredRoundDetails")
    return (
        <>
            {isLoadingRoundDetails ? <Box className='skeleton' height={"calc(100%)"} marginTop={2}>

            </Box> : <>
                {
                    Object.keys(filteredRoundDetails)?.length > 0 ? <>
                        <Grid item container xs={12} spacing={"1.25rem"} display={"flex"} sx={{ paddingBottom: '2rem', flexDirection: "row" }}
                            //  gridTemplateAreas={isLargeScreen ? `"first aside" "second aside" "third aside" "forth aside" "fifth aside" "sixth aside"` : `"first" "aside" "second" "third" "forth" "fifth" "sixth"`}
                            // gridTemplateColumns={isLargeScreen ? "2.5fr 1.5fr" : '1fr'} 
                            justifyContent={"space-between"}>
                            <Grid item container xs={12} sm={12} md={7} lg={7.5} spacing={"1.25rem"}>
                                {nameTypeDetails && nameTypeDetails?.length > 0 && <Grid sx={{ gridArea: 'first', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                                        <CardHeader title={"Name & Type"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=0`) }} />

                                        <CardContentList data={nameTypeDetails} />
                                    </Card>
                                </Grid>}
                                {!isLargeScreen && <Grid sx={{ gridArea: 'aside', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                    {((roundDetails?.fund_utilization_highlights && roundDetails?.fund_utilization_highlights?.length > 0) || (roundDetails?.fund_allocation_percentages && roundDetails?.fund_allocation_percentages?.length > 0)) && <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                                        <CardHeader title={"Fund Utilization"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=3`) }} />
                                        {roundDetails?.fund_utilization_highlights && roundDetails?.fund_utilization_highlights?.length > 0 && <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                            <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                                                Fund Utilization Highlights
                                            </Typography>
                                            <RichTextDisplay htmlContent={roundDetails?.fund_utilization_highlights} color={theme.palette.customColors.g200} />
                                        </Card>}
                                        {roundDetails?.fund_allocation_percentages && roundDetails?.fund_allocation_percentages?.length > 0 && <>
                                            <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={'0.625rem'}>
                                                Fund Allocation
                                            </Typography>
                                            <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden' }}>
                                                <PieChart graphData={roundDetails?.fund_allocation_percentages} height='100%' width="100%" />
                                            </Card>
                                        </>}
                                    </Card>}
                                </Grid>}
                                {((roundDetails?.round_highlights && roundDetails?.round_highlights?.length > 0) || (roundSizeValuationDetails && roundSizeValuationDetails?.length > 0)) && <Grid sx={{ gridArea: 'second', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>

                                        <CardHeader title={"Round Size & Valuation"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=1`) }} />
                                        {(roundDetails?.round_highlights && roundDetails?.round_highlights?.length > 0) && <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                            <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                                                Round Highlights
                                            </Typography>
                                            <RichTextDisplay htmlContent={roundDetails?.round_highlights} color={theme.palette.customColors.g200} />
                                        </Card>}
                                        {/* <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Total Round Size</Typography>
                            <Typography marginBlock={"auto"}>
                                {roundDetails?.total_round_size_final}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Round Valuation</Typography>
                            <Typography marginBlock={"auto"}>
                                {roundDetails?.round_valuation_final}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Minimum Cheque Size</Typography>
                            <Typography marginBlock={"auto"}>
                                {roundDetails?.minimum_cheque_size_final}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Have you already received any Term Sheet?</Typography>
                            <Typography marginBlock={"auto"}>
                                {roundDetails?.received_termsheet ? "Yes" : 'No'}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>What funding amount has been committed so far?</Typography>
                            <Typography marginBlock={"auto"}>
                                {roundDetails?.committed_funding_amount_final}
                            </Typography>
                        </Grid>
                    </Grid> */}
                                        {roundSizeValuationDetails && roundSizeValuationDetails?.length > 0 && <CardContentList data={roundSizeValuationDetails} />}
                                    </Card>
                                </Grid>}
                                {roundDetails?.investors && roundDetails?.investors?.length > 0 && <Grid sx={{ gridArea: 'third', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                                        <CardHeader title={"Current Investors"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=2`) }} />
                                        <Grid
                                            container
                                            xs={12}
                                            sx={{
                                                border: `1px solid ${theme?.palette?.customColors?.b75}`,
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Table>
                                                <TableHead>
                                                    <TableRow sx={{
                                                        padding: "12px 16px",
                                                        backgroundColor:
                                                            theme?.palette?.customColors?.g200
                                                    }}>
                                                        <TableCell>
                                                            <Typography fontWeight={400} color={theme?.palette?.customColors?.g50}>Investor Name</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography fontWeight={400} color={theme?.palette?.customColors?.g50}>
                                                                Investor Email
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {roundDetails?.investors?.length > 0 && roundDetails?.investors?.map((investor, index) => {
                                                        return <TableRow
                                                            item
                                                            key={index}
                                                            xs={12}
                                                            display={"flex"}
                                                            justifyContent={"space-between"}
                                                            sx={{
                                                                padding: "12px 16px",
                                                                backgroundColor:
                                                                    index % 2 === 0 ? theme?.palette?.customColors?.b50 : '',
                                                            }}
                                                        >
                                                            <TableCell>
                                                                <Typography fontWeight={500} color={theme?.palette?.customColors?.g300}>
                                                                    {investor?.name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography fontWeight={400} color={theme?.palette?.customColors?.g200}>
                                                                    {investor?.email}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    })}

                                                    {roundDetails?.investors?.length == 0 && <TableRow
                                                        item
                                                        xs={12}
                                                        display={"flex"}
                                                        justifyContent={"space-between"}
                                                        sx={{
                                                            padding: "12px 16px",
                                                            backgroundColor:
                                                                theme?.palette?.customColors?.b50,
                                                        }}
                                                    >
                                                        <TableCell colSpan={2} marginInline={"auto"} sx={{ textAlign: 'center' }}>No Current Investors</TableCell>
                                                    </TableRow>}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Card>
                                </Grid>}
                                {((roundDetails?.traction_highlights && roundDetails?.traction_highlights?.length > 0) || (roundDetails?.key_metrics && roundDetails?.key_metrics?.length > 0)) && <Grid sx={{ gridArea: 'forth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>

                                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                                        <CardHeader title={"Current Traction & Runway"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=4`) }} />
                                        {roundDetails?.traction_highlights && roundDetails?.traction_highlights?.length > 0 && <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                            <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                                                Traction Highlight
                                            </Typography>
                                            <RichTextDisplay htmlContent={roundDetails?.traction_highlights} color={theme.palette.customColors.g200} />
                                        </Card>}
                                        {roundDetails?.key_metrics && roundDetails?.key_metrics?.length > 0 && roundDetails?.key_metrics?.map((metric, idx) => {
                                            return <>
                                                <Typography fontWeight={500} mt={'0.75rem'} mb={'0.375rem'} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                                                    {metric?.heading}
                                                </Typography>
                                                {metric?.properties && Array.isArray(metric?.properties) && metric?.properties?.length > 0 && <Grid
                                                    container
                                                    xs={12}
                                                    sx={{
                                                        border: `0px solid ${theme?.palette?.customColors?.b75}`,
                                                        borderRadius: "8px",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    <CardContentList data={metric?.properties?.map(item => ({label: item?.key, ...item}))}  />
                                                </Grid>}
                                            </>
                                        })}
                                    </Card>
                                </Grid>}
                                {roundDetails?.faqs && roundDetails?.faqs?.length > 0 && <Grid sx={{ gridArea: 'fifth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" } }} item>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginInline: { xs: "0.5rem -0.5rem", sm: 0 } }}>
                                        <CardHeader title={"FAQs"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=6`) }} />
                                        {
                                            roundDetails?.faqs?.map(({ question, answer }, index) => {
                                                return <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                                    <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={1}>
                                                        {question}
                                                    </Typography>
                                                    <RichTextDisplay htmlContent={answer} />
                                                    {/* <Typography fontWeight={400} fontSize={"0.875rem"} color={theme.palette.customColors.g200}>
                                    {answer}
                                </Typography> */}
                                                </Card>
                                            })
                                        }
                                        {
                                            roundDetails?.faqs && roundDetails?.faqs?.length === 0 && <>
                                                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                                    <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200} textAlign={"center"}>
                                                        No FAQs available
                                                    </Typography>
                                                </Card>
                                            </>
                                        }
                                    </Card>
                                </Grid>}
                                {((roundDetails?.exit_highlights && roundDetails?.exit_highlights?.length > 0) || (exitStrategyDetails && exitStrategyDetails?.length > 0) || (otherDetails && otherDetails?.length > 0)) && <Grid sx={{ gridArea: 'sixth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>

                                        <CardHeader title={"Exit Strategy & Timeline"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=7`) }} />
                                        {roundDetails?.exit_highlights && roundDetails?.exit_highlights?.length > 0 && <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                            <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                                                Exit Highlight
                                            </Typography>
                                            <RichTextDisplay htmlContent={roundDetails?.exit_highlights} color={theme.palette.customColors.g200} />
                                        </Card>}
                                        {exitStrategyDetails && exitStrategyDetails?.length > 0 && <CardContentList data={exitStrategyDetails} />}
                                        {/* <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Runway Left</Typography>
                            <Typography>
                                {roundDetails?.runway_left > "1" ? roundDetails?.runway_left + " Months" : roundDetails?.runway_left == "1" ? roundDetails?.runway_left + " Month" : "-"}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Monthly Net Burn</Typography>
                            <Typography>
                                {roundDetails?.monthly_net_burn_final ? roundDetails?.monthly_net_burn_final : "-"}
                            </Typography>
                        </Grid>
                    </Grid> */}
                                        {(otherDetails && otherDetails?.filter(item => notEmpty(item?.value))?.length > 0) &&
                                            <>
                                                <Typography fontWeight={500} mt={'0.75rem'} mb={'0.375rem'} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                                                    Other Details
                                                </Typography>
                                                <CardContentList data={otherDetails?.filter(item => notEmpty(item?.value))} />
                                            </>}
                                        {/* <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Round Start Date</Typography>
                            <Typography>
                                {handleDateValues(roundDetails?.final_round_start_date, "-")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>Round Closing Date</Typography>
                            <Typography>
                                {handleDateValues(roundDetails?.final_round_closing_date, "-")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Round Status</Typography>
                            <Typography>
                                {roundDetails?.round_status_info?.name}
                            </Typography>
                        </Grid>
                    </Grid> */}
                                    </Card>
                                </Grid>}
                            </Grid>
                            {
                                isLargeScreen && <Grid xs={12} sm={12} md={5} lg={4.5} sx={{ marginInline: { xs: 'auto', sm: 0 } }} item>
                                    {((roundDetails?.fund_utilization_highlights && roundDetails?.fund_utilization_highlights?.length > 0) || (roundDetails?.fund_allocation_percentages && roundDetails?.fund_allocation_percentages?.length > 0)) && <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                                        <CardHeader title={"Fund Utilization"} action={() => { navigate(`/rounds/edit_round/${round_id}?page=3`) }} />
                                        {roundDetails?.fund_utilization_highlights && roundDetails?.fund_utilization_highlights?.length > 0 && <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                            <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200}>
                                                Fund Utilization Highlights
                                            </Typography>
                                            <RichTextDisplay htmlContent={roundDetails?.fund_utilization_highlights} color={theme.palette.customColors.g200} />
                                        </Card>}
                                        {roundDetails?.fund_allocation_percentages && roundDetails?.fund_allocation_percentages?.length > 0 && <>
                                            <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={'0.625rem'}>
                                                Fund Allocation
                                            </Typography>
                                            <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden' }}>
                                                <PieChart graphData={roundDetails?.fund_allocation_percentages} height='100%' width="100%" />
                                            </Card>
                                        </>}
                                    </Card>}
                                </Grid>
                            }
                        </Grid>
                    </> : <NoData />
                }
            </>}
        </>
    )
}

export default RoundInformation