import { useTheme } from '@emotion/react';
import { ContentCopyOutlined, CopyAllOutlined, FileCopy } from '@mui/icons-material'
import { Autocomplete, capitalize, Chip, CircularProgress, Dialog, Divider, Grid, IconButton, InputAdornment, Modal, Paper, TextField, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { ReactComponent as CloseIcon } from "assets/close_icon.svg";
import { FetchRoundAllInvestorsEmails, FetchRoundInvestorsData } from 'api/round';
import EmailAutocomplete from 'common-components/EmailAutocomplete';
import { Field, FormikProvider, useFormik } from 'formik';
import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomAutoComplete from 'ui-components/CustomAutoComplete';
import { B300 } from 'ui-components/CustomButton';
import CustomValidationText from 'ui-components/CustomValidationText';
import CustomInputField from 'ui-components/formik/CustomInputField';
import ModalComponent from 'ui-components/ModalComponent';
import { handleCopyClick } from 'utils/index';
import * as Yup from "yup";
const useStyles = makeStyles({
    customInput: {
        // '&  > div.MuiInputBase-root': {
        //     padding: 0
        // }
    }
});

const InviteStartupPopup = ({ open, handleClose, handleSubmission, isInvitingStartup = false }) => {
    const theme = useTheme();
    // const { id: round_id } = useParams();
    const classes = useStyles();
    const details = useSelector((state) => state?.investorDetails?.data);
    // const { data: personData, refetch: refetchListingLisiting, isLoading: isLoadingRoundInvestors } = FetchRoundAllInvestorsEmails({ id: round_id });
    // console.log(personData?.data?.map(item => ({name: item?.name, email: item?.email})), "personData")
    const formik = useFormik({
        initialValues: {
            email: "",
            startup_name: '',
            message: '',
            copy_link: '',
        },
        validationSchema: Yup.object({
            startup_name: Yup.string().required("Please enter startup name"),
            email: Yup.string().required("Please enter email"),
        }),
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const payload = {
                // "startup_id": details?._id,
                // "startup_round_id": round_id,
                "email": values?.email,
                "message": values?.email,
                "startup_name": values?.startup_name
            }
            await handleSubmission({ payload })
            resetForm()
        },
    });

    const [copied, setCopied] = React.useState(false)

    return (
        <ModalComponent open={open} onClose={handleClose} style={{ maxWidth: 640, width: "100%" }}>
            <Paper
                sx={{
                    background: theme.palette.customColors.whiteBG,
                    borderRadius: "8px",
                }}
            >
                <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
                    <CloseIcon width={40} height={40} onClick={handleClose} />
                </IconButton>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} onKeyDown={(e) => {
                        if ((e.key === "Enter" && !e.shiftKey)) {
                            e.preventDefault();
                        }
                    }}>
                        <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: "1.5rem",
                                        fontWeight: 700,
                                        lineHeight: "28px",
                                        color: theme.palette.customColors.g300,
                                    }}
                                >
                                    Invite Startup
                                </Typography>
                            </Grid>
                            {/* {console.log(details,"details")} */}
                            <Grid item xs={12}>
                                <Grid item xs={12} container justifyContent={"space-between"}>
                                    <Grid item xs={12}>
                                        <CustomInputField
                                            name="copy_link"
                                            label={"Copy Invite Link"}
                                            value={details?.backrr_base_url}
                                            tabIndex={0}
                                            sx={{ marginBottom: 1 }}
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end" sx={{ position: 'absolute', right: '18px' }}>
                                                        <IconButton disableRipple edge="end" onClick={() => handleCopyClick(details?.backrr_base_url + "/d", copied, setCopied)} sx={{ borderRadius: '5px' }}>
                                                            <Tooltip open={copied} title={copied ? "Copied to Clipboard" : null}>
                                                                <Typography color={copied ? "inherit" : theme.palette.customColors.b300}>
                                                                    Copy
                                                                </Typography>
                                                            </Tooltip>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }
                                            }
                                        />

                                    </Grid>
                                    <Grid position={'relative'} item xs={12}
                                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    >
                                        <Divider sx={{ flex: 1 }} />
                                        <Typography sx={{ width: "2rem", textAlign: "center" }}>OR</Typography>
                                        <Divider sx={{ flex: 1 }} />
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Field name="emails" placeholder="Search by email or name" label={"Invite startup"} options={
                                            // !isLoadingRoundInvestors ? personData?.data?.map(item => ({ name: item?.name, email: item?.email })) :
                                            []} component={EmailAutocomplete} multiple={false} inputStyle={{ marginBottom: 0 }} />
                                        {formik.errors.emails && <CustomValidationText value={formik.errors.emails} />}
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <CustomInputField
                                            name="startup_name"
                                            label={"Name"}
                                            placeholder={"Enter Startup name"}
                                            value={formik?.values?.startup_name}
                                            sx={{ marginBottom: 1 }}
                                            tabIndex={1}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomInputField
                                            name="email"
                                            label={"Email"}
                                            placeholder={"Enter email"}
                                            value={formik?.values?.email}
                                            sx={{ marginBottom: 1 }}
                                            tabIndex={2}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <B300
                                    type="submit"
                                    fullWidth
                                    sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", height: '52px' }}
                                    disabled={isInvitingStartup}
                                >
                                    {isInvitingStartup ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.2rem'} /> : "Invite"}
                                </B300>
                            </Grid>
                        </Grid>
                    </form>
                </FormikProvider>
            </Paper>
        </ModalComponent>
    )
}

export default InviteStartupPopup