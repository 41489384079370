import { Box, useMediaQuery } from '@mui/material'
import React from 'react'
import { useTheme } from '@emotion/react';

const ThemeBGWrapper = ({ children, bgColor }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Box sx={{ background: bgColor ? bgColor: theme.palette?.customColors?.yellow_bg, minHeight: 'calc(100vh - 20px)', maxWidth: isNotLargeScreen ? "100%" : "calc(100% - 5.125rem)", height: '100%', borderRadius: isNotLargeScreen ? 0 : "24px 0 0 0", position: 'fixed', top: isNotLargeScreen ? 0 : '20px', width: '100%', right: 0}}>
            {children}
        </Box>
    )
}

export default ThemeBGWrapper