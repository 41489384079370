import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { B300 } from "./CustomButton";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Add, Create } from "@mui/icons-material";

const NoData = ({ Actions = () => {}, sx={} }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100% - 164px)"
      textAlign="center"
      padding={3}
      sx={{...sx}}
      height={"60vh"}
      // height={"100%"}
      width={"-webkit-fill-available"}
      // width={"100%"}
    >
      {/* <img
                src="/path-to-your-image/no-data-illustration.png" // Replace with your image path
                // alt="No Data"
                style={{ width: '200px', height: 200, marginBottom: '20px', background: 'gray', borderRadius: 8 }} // Adjust the size as needed
            /> */}
      <Typography variant="title" gutterBottom>
        No Data Available
      </Typography>
      <Typography variant="body1" marginBottom={2} gutterBottom>
        It seems like there are no data available at the moment.
      </Typography>
      <Actions />
      {/* <B300 onClick={() => navigate("/rounds/create_round")} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600 }}
            // startIcon={<Create fontSize='1.25rem' />}
            >
                
            </B300> */}
    </Box>
  );
};

export default NoData;
