import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const B50 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.b50,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.b50,
  },
}));
const B75 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.b75,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.b75,
  },
}));
const B100 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.b100,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.b100,
  },
}));

const B200 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.b200,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.b200,
  },
}));
const B300 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.b300,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.b300,
  },
}));
const B400 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.b400,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.b400,
  },
}));
const B500 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.b500,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.b500,
  },
}));
const R50 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.r50,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.r50,
  },
}));
const R75 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.r75,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.r75,
  },
}));
const R100 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.r100,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.r100,
  },
}));
const R200 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.r200,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.r200,
  },
}));
const R300 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.r300,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.r300,
  },
}));
const R400 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.r400,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.r400,
  },
}));
const R500 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.r500,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.r500,
  },
}));
const Y50 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.y50,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.y50,
  },
}));
const Y75 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.y75,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.y75,
  },
}));
const Y100 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.y100,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.y100,
  },
}));
const Y200 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.y200,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.y200,
  },
}));
const Y300 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.y300,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.y300,
  },
}));
const Y400 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.y400,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.y400,
  },
}));
const Y500 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.y500,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.y500,
  },
}));
const G50 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.g50,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.g50,
  },
}));
const G75 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.g75,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.g75,
  },
}));
const G100 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.g100,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.g100,
  },
}));
const G200 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.g200,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.g200,
  },
}));
const G300 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.g300,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.g300,
  },
}));
const G400 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.g400,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.g400,
  },
}));
const G500 = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.g500,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.g500,
  },
}));
const PRIMARY = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
const SECONDARY = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));
const SUCCESS = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette.success.main,
  },
}));
const WARNING = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));
const ERROR = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette.error.main,
  },
}));
const INPUTFIELD = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.inputField,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.inputField,
  },
}));
const DISABLE = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.disable,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.disable,
  },
}));
const INFORMATION = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.information,
  color: '#fff',
  textTransform: "capitalize",
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.information,
  },
}));
const WHITEBG = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.whiteBG,
  textTransform: "capitalize",
  borderRadius: '8px', color: 'black',

  border: '2px solid black',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.whiteBG,
  },
}));
const GREYBORDER = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette?.customColors?.whiteBG,
  textTransform: "capitalize",
  borderRadius: '8px', color: 'black',

  border: '2px solid grey',
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.whiteBG,
  },
}));
const BLUEBORDER = styled(Button)(({ theme, color = "blue" }) => ({
  backgroundColor: theme.palette?.customColors?.whiteBG,
  color: color,
  textTransform: "capitalize",
  borderRadius: '8px',
  border: `2px solid ${color}`,
  '&:hover': {
    backgroundColor: theme.palette?.customColors?.whiteBG,
  },
}));



export { B50, B75, B100, B200, B300, B400, B500, R50, R75, R100, R200, R300, R400, R500, Y50, Y75, Y100, Y200, Y300, Y400, Y500, G50, G75, G100, G200, G300, G400, G500, PRIMARY, SECONDARY, SUCCESS, WARNING, ERROR, INPUTFIELD, WHITEBG, DISABLE, INFORMATION, GREYBORDER, BLUEBORDER };
