import React, { lazy } from 'react'
import MainContent from './MainContent'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import Header from "./Header"
import BreadcrumbsHeader from "./BreadcrumbsHeader"
import Loadable from 'common-components/Loadable'
// const Sidebar =Loadable(lazy(() => import('./Sidebar')))

const MainLayout = () => {
    const currentBackground = document.getElementsByTagName("body");
    currentBackground[0].style.backgroundColor = "#3538CD"
    // console.log(currentBackground[0].style.backgroundColor === "#3538CD","currentBackground")
    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div style={{ flexGrow: 1 }}>
                {/* <Header /> */}
                <MainContent>
                    {/* <BreadcrumbsHeader /> */}
                    <Outlet />
                </MainContent>
            </div>
        </div>
    )
}

export default MainLayout